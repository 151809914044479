import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import '../styles/Markets.css';
import { AuthContext } from '../context/AuthContext';
import { getMarketStatus, getDateWhenClosesOrOpens, calculateTimeRemaining, getChangeColor } from '../helper/MarketsHelper';
import Select from 'react-select'; // Import react-select
import { customStyles } from '../helper/StylesHelper';


/*
Pre-market is 4 AM EST - 9:30 AM EST
Regular hours is 9:30AM - 4PM EST
After-hours is 4 PM EST - 8 PM EST
*/

export default function Markets() {
  const [stock, setStock] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [stockLists, setStockLists] = useState([]);
  const [popularStocks, setPopularStocks] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [newListName, setNewListName] = useState('');
  const navigate = useNavigate();
  const { userToken, isAuthenticated } = useContext(AuthContext);

  //eslint-disable-next-line
  const [marketStatus, setMarketStatus] = useState('');
  // Added state variables for sort options
  const [popularStocksSortOption, setPopularStocksSortOption] = useState('change');
  const [recentSearchesSortOption, setRecentSearchesSortOption] = useState('change');
  const [stockListSortOptions, setStockListSortOptions] = useState({});
  const [nextEventTimeStamp, setNextEventTimeStamp] = useState(null);


  //eslint-disable-next-line
  const [timeRemaining, setTimeRemaining] = useState(null);

  // Options for the react-select component
  const sortOptions = [
    { value: 'change', label: '% Change' },
    { value: 'price', label: 'Price' },
    { value: 'alphabetical', label: 'Alphabetical' },
  ];
  
  useEffect(() => {
    getDateWhenClosesOrOpens().then((timeStamp) => {
      setNextEventTimeStamp(timeStamp);
    });
    getMarketStatus().then((data) => {
      setMarketStatus(data?.message);
    }
    );
  }, []);


 


  useEffect(() => {
    if (nextEventTimeStamp) {
      const updateTimer = () => {
        const remaining = calculateTimeRemaining(nextEventTimeStamp);
        setTimeRemaining(remaining);
      };
      // Initial call to set the time remaining when the component mounts
      updateTimer();
      // Set interval to update every minute
      const interval = setInterval(() => {
        updateTimer();
      }, 1000); // Update every second

      // Cleanup interval on component unmount
      return () => clearInterval(interval);
    }
  }, [nextEventTimeStamp]);

  

  // Fetch popular stocks
  const fetchPopularStocks = async () => {
    try {
      const response = await axios.get(`${config.backendUrl}/api/polygon/popularstocks`);
      setPopularStocks(response.data);
    } catch (error) {
      console.error('Error fetching popular stocks data:', error);
    }
  };

  // Fetch recent searches
  const fetchRecentSearches = async () => {
    if (!isAuthenticated) {
      return;
    }
    try {
      const response = await axios.get(`${config.backendUrl}/api/polygon/users/recentsearches`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      setRecentSearches(response.data);
    } catch (error) {
      console.error('Error fetching recent searches:', error);
    }
  };

  // Fetch stock lists
  const fetchStockLists = async () => {
    if (!isAuthenticated) {
      return;
    }
    try {
      const response = await axios.get(`${config.backendUrl}/api/polygon/stocklists`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      setStockLists(response.data);
    } catch (error) {
      console.error('Error fetching stock lists:', error);
    }
  };

  // Debounced function to fetch suggestions
  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  // Fetch ticker suggestions
const fetchTickerSuggestions = async (query) => {
  if (!query.trim()) {
      setSuggestions([]);
      return;
  }

  try {
      // Call the backend API for ticker suggestions
      const response = await axios.get(`${config.backendUrl}/api/polygon/tickersuggestions`, {
          params: { query }
      });

      // Update the suggestions in the state
      setSuggestions(response.data || []);
  } catch (error) {
      console.error('Error fetching ticker suggestions:', error);
  }
};



  const handleInputChange = (e) => {
    const query = e.target.value;
    setStock(query);
    debounceFetchSuggestions(query);
  };

  const debounceFetchSuggestions = debounce(fetchTickerSuggestions, 500);

  const handleSearchStock = async (e) => {
    if (e.key === 'Enter' && stock.trim()) {
      const ticker = stock.trim().toUpperCase();

      try {
        if (isAuthenticated) {
          await axios.post(
            `${config.backendUrl}/api/polygon/users/recentsearches`,
            { ticker },
            {
              headers: { Authorization: `Bearer ${userToken}` },
            }
          );

          setRecentSearches((prev) => {
            const updatedSearches = prev.filter((t) => t.ticker !== ticker);
            updatedSearches.unshift({ ticker, companyName: 'Loading...' });
            return updatedSearches.slice(0, 7);
          });
        }

      } catch (error) {
        console.error('Error adding recent search:', error);
        alert(`Error searching for stock ${ticker}.`);
      }
      navigate(`/stocks/${ticker}`);
    }
    
  };

  const handleSuggestionClick = async (ticker) => {
    try {
      if (isAuthenticated) {
        await axios.post(
          `${config.backendUrl}/api/polygon/users/recentsearches`,
          { ticker },
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        );
        setRecentSearches((prev) => {
          const updatedSearches = prev.filter((t) => t.ticker !== ticker);
          updatedSearches.unshift({ ticker, companyName: 'Loading...' });
          return updatedSearches.slice(0, 7);
        });
      }
      navigate(`/stocks/${ticker}`);
    } catch (error) {
      console.error('Error adding recent search:', error);
    }
  };

  const handleCreateList = async () => {
    if (!newListName.trim()) {
      alert('Please enter a list name');
      return;
    }
    if (newListName.trim()) {
      try {
        const response = await axios.post(
          `${config.backendUrl}/api/polygon/stocklists`,
          { name: newListName },
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        );
        setStockLists([...stockLists, response.data]);
        setNewListName('');
        setShowPopover(false);
      } catch (error) {
        console.error('Error creating new stock list:', error);
      }
    }
  };

  const handleDeleteList = async (listId) => {
    if (!window.confirm('Are you sure you want to delete this stock list?')) {
      return;
    }

    try {
      await axios.delete(`${config.backendUrl}/api/polygon/stocklists/${listId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      setStockLists((prev) => prev.filter((list) => list?._id !== listId));
    } catch (error) {
      console.error('Error deleting stock list:', error);
    }
  };

  // Handle sort option change for user stock lists
  const handleStockListSortOptionChange = (listId, selectedOption) => {
    setStockListSortOptions((prev) => ({
      ...prev,
      [listId]: selectedOption.value,
    }));
  };



  // Sorting function for stocks
  const sortStocks = (stocks, sortOption) => {
    const stocksCopy = [...stocks]; // Avoid mutating the original array
    switch (sortOption) {
      case 'alphabetical':
        return stocksCopy.sort((a, b) => a.ticker.localeCompare(b.ticker));
      case 'price':
        return stocksCopy.sort((a, b) => b.currentPrice - a.currentPrice); // Descending order
      case 'change':
        return stocksCopy.sort((a, b) => b.todaysPercentChange - a.todaysPercentChange); // Descending order
      default:
        return stocksCopy;
    }
  };

  
  useEffect(() => {
    fetchPopularStocks();
    fetchRecentSearches();
    fetchStockLists();
 //eslint-disable-next-line
  }, []);



  const renderStockLists = () => {
    if (stockLists.length === 0) {
      return null; // No stock lists available
    }

    return stockLists.map((list) => {
      const sortOption = stockListSortOptions[list?._id] || 'change';
      const sortedStocks = sortStocks(list?.stocks || [], sortOption);

      return (
        <div key={list?._id} className="MarketsList">
          <div className="MarketListHeaderWrapper">
            <h3 className="MarketsListHeader">{list?.name}</h3>
            <div className="ListActionButtons">
              {/* Added react-select dropdown for sorting */}
              <Select
                className="SortSelect"
                value={sortOptions.find((option) => option.value === sortOption)}
                onChange={(selectedOption) => handleStockListSortOptionChange(list?._id, selectedOption)}
                options={sortOptions}
                isSearchable={false}
                styles={customStyles}
                unstyled
                title="Sort stocks"
              />
              <button className="CreatePostFeedButton" onClick={() => handleDeleteList(list?._id)}>
                Delete
              </button>
            </div>
          </div>
          {sortedStocks.length === 0 ? (
            <div className="MarketItem">
              <div className="MarketItemInfo">
                <p className="MarketName">None added</p>
              </div>
            </div>
          ) : (
            sortedStocks.map((stock, idx) => (
              <div key={idx} className="MarketItem" onClick={() => navigate(`/stocks/${stock?.ticker}`)}>
                <div className="MarketItemInfo">
                  <p className="MarketTicker bold">{stock?.ticker}</p>
                  <p className="MarketName">{stock?.companyName}</p>
                </div>
                <div className="MarketsPriceChangeWrapper">
                <div className="MarketItemPrice">${stock?.todaysClosePrice > 0 ? stock?.todaysClosePrice?.toFixed(2) : stock?.currentPrice?.toFixed(2)}</div>
                  <div className={`MarketItemChange bold ${stock?.todaysPercentChange >= 0 ? 'ma-positive' : 'ma-negative'}`}
                    style={{ backgroundColor: getChangeColor(stock?.todaysPercentChange) }} 
                    >
                    {stock?.todaysPercentChange >= 0 ? '+' : ''}
                    {stock?.todaysPercentChange?.toFixed(2)}%
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      );
    });
  };

  return (
    <div className="MarketsContainer">
      {/* Search Bar */}
      <div className="MarketsSearchWrapper">
        <input
          className="MarketsSearchBar bold"
          type="text"
          placeholder="Search stocks and indices"
          value={stock}
          onChange={handleInputChange}
          onKeyDown={handleSearchStock}
        />
        {suggestions.length > 0 && stock && (
          <ul className="SuggestionsDropdown">
            {suggestions.map((suggestion) => (
              <li
                key={suggestion?.ticker}
                onClick={() => handleSuggestionClick(suggestion?.ticker)}
                className="SuggestionItem"
              >
                {suggestion?.ticker} - {suggestion?.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      {/* <span className="MarketTimeLeftHeader bold">
  The {marketStatus} </span>
  
  <>It will {marketStatus.includes('open') ? 'close' : 'open'} in
  <span className="time-value bold">{timeRemaining?.hours}</span> hour{timeRemaining?.hours !== 1 ? 's' : ''},
  <span className="time-value bold">{timeRemaining?.minutes}</span> minute{timeRemaining?.minutes !== 1 ? 's' : ''},
  and <span className="time-value bold">{timeRemaining?.seconds}</span> second{timeRemaining?.seconds !== 1 ? 's' : ''}.
  </> */}


      {/* Popular Stocks */}
      <div className="MarketsListContainer">
        <div className="MarketsList">
          <div className="MarketListHeaderWrapper">
            <h3 className="MarketsListHeader">Popular Stocks</h3>
            {/* Added react-select dropdown for sorting Popular Stocks */}
            <Select
              className="SortSelect"
              value={sortOptions.find((option) => option.value === popularStocksSortOption)}
              onChange={(selectedOption) => setPopularStocksSortOption(selectedOption.value)}
              options={sortOptions}
              isSearchable={false}
              unstyled
              styles={customStyles}
              title="Sort stocks"
            />
          </div>
          {sortStocks(popularStocks, popularStocksSortOption).map((stock, idx) => (
            <div key={idx} className="MarketItem" onClick={() => navigate(`/stocks/${stock?.ticker}`)}>
              <div className="MarketItemInfo">
                <p className="MarketTicker bold">{stock?.ticker}</p>
                <p className="MarketName">{stock?.companyName}</p>
              </div>
              <div className="MarketsPriceChangeWrapper">
              <div className="MarketItemPrice">${stock?.todaysClosePrice > 0 ? stock?.todaysClosePrice?.toFixed(2) : stock?.currentPrice?.toFixed(2)}</div>
                  <div 
                    style={{ backgroundColor: getChangeColor(stock?.todaysPercentChange) }} 
                  className={`MarketItemChange bold ${stock?.todaysPercentChange >= 0 ? 'ma-positive' : 'ma-negative'}`}>
                    {stock?.todaysPercentChange >= 0 ? '+' : ''}
                    {stock?.todaysPercentChange?.toFixed(2)}%
                  </div>
                </div>
            </div>
          ))}
        </div>

        {/* Recent Searches */}
        {isAuthenticated && (
          <div className="MarketsList">
            <div className="MarketListHeaderWrapper">
              <h3 className="MarketsListHeader">Recent Searches</h3>
              <Select
                className="SortSelect"
                value={sortOptions.find((option) => option.value === recentSearchesSortOption)}
                onChange={(selectedOption) => setRecentSearchesSortOption(selectedOption.value)}
                options={sortOptions}
                isSearchable={false}
                unstyled
                styles={customStyles}
                title="Sort stocks"
              />
            </div>
            <>
 
            {recentSearches?.length === 0 && 
              <div className="MarketItem">
                <div className="MarketItemInfo">
                  <p className="MarketName">No recent searches</p>
                </div>
              </div>
            }
            {sortStocks(recentSearches, recentSearchesSortOption).map((stock, idx) => (
              <div key={idx} className="MarketItem" onClick={() => handleSuggestionClick(stock?.ticker)}>
                <div className="MarketItemInfo">
                  <p className="MarketTicker bold">{stock?.ticker}</p>
                  <p className="MarketName">{stock?.companyName}</p>
                </div>
                <div className="MarketsPriceChangeWrapper">
                  <div className="MarketItemPrice">${stock?.todaysClosePrice > 0 ? stock?.todaysClosePrice?.toFixed(2) : stock?.currentPrice?.toFixed(2)}</div>
                  <div 
                    style={{ backgroundColor: getChangeColor(stock?.todaysPercentChange) }} 
                  className={`MarketItemChange bold ${stock?.todaysPercentChange >= 0 ? 'ma-positive' : 'ma-negative'}`}>
                    {stock?.todaysPercentChange >= 0  ? '+' : ''}
                    {stock?.todaysPercentChange?.toFixed(2)}%
                  </div>
                </div>
              </div>
            ))}
            </>
          </div>
        )}

        {/* User Stock Lists */}
        {renderStockLists()}
      </div>

      {/* Create New List Button */}
      <div className="CreateListContainer">
        {isAuthenticated && (
          <button
            style={{ width: '15rem' }}
            className="CreatePostFeedButton"
            onClick={() => setShowPopover(true)}
          >
            Create new list
          </button>
        )}

        {showPopover && (
          <div className="CreateListPopover">
            <input
              type="text"
              value={newListName}
              onChange={(e) => setNewListName(e.target.value)}
              placeholder="List Name"
              className="CreateListInput"
            />
            <button className="CreatePostFeedButton" onClick={handleCreateList}>
              Create
            </button>
            <button className="CreatePostFeedButton" onClick={() => setShowPopover(false)}>
              Cancel
            </button>
          </div>
        )}
      </div>
      
    </div>
  );
}