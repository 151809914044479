import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./styles/App.css";
import Header from "./components/Header";

import MrVestr from "./components/MrVestr";
import Profile from "./components/Profile";
import Feed from "./components/Feed";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import AuthProvider, { AuthContext } from "./context/AuthContext";
import CreatePost from "./components/CreatePost";
import Settings from "./components/Settings";
import PostView from "./components/PostView";
import Overview from "./components/Overview";
import VestrPlus from "./components/VestrPlus";
import Markets from "./components/Markets";
import Stock from "./components/Stock";
import News from "./components/News";
import TermsOfService from "./components/TermsOfService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PaymentComplete from "./components/PaymentComplete";
import Analytics from "./components/Analytics";

function App() {
  const [isUserLink, setisUserLink] = useState(false);
  useEffect(() => {
    setisUserLink(window.location.pathname.includes("analytics"));
  }, []);
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AuthProvider>
        <Router>
          {!isUserLink && (
            <div className="App">
              <Header />
              <div className="Content">
                <Routes>
                  <Route
                    path="/payment-complete"
                    element={<PaymentComplete />}
                  />
                  <Route path="/news" element={<News />} />
                  <Route path="/" element={<Overview />} />
                  <Route path="/vestrplus" element={<VestrPlus />} />
                  <Route path="/feed" element={<Feed />} />
                  <Route path="/post/:postId" element={<PostView />} />
                  <Route path="/mrvestr" element={<MrVestr />} />
                  <Route path="/terms" element={<TermsOfService />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route
                    path="/profile/:username"
                    element={
                      <ProtectedRoute>
                        <Profile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/createpost"
                    element={
                      <ProtectedRoute>
                        <CreatePost />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/signin"
                    element={
                      <AuthRedirect>
                        <SignIn />
                      </AuthRedirect>
                    }
                  />
                  <Route
                    path="/signup"
                    element={
                      <AuthRedirect>
                        <SignUp />
                      </AuthRedirect>
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <ProtectedRoute>
                        <Settings />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/markets" element={<Markets />} />
                  <Route path="/stocks/:ticker" element={<Stock />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </div>
            </div>
          )}
          {isUserLink && (
            <div className="App">
              <div className="Content">
                <Routes>
                  <Route path="/analytics" element={<Analytics />} />
                </Routes>
              </div>
            </div>
          )}
        </Router>
      </AuthProvider>
    </LocalizationProvider>
  );
}

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to="/signin" />;
};

const AuthRedirect = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);

  return isAuthenticated ? <Navigate to="/" /> : children;
};

export default App;
