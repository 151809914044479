import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Chart from 'chart.js/auto';
import axios from 'axios';
import '../styles/Stock.css';
import {
   getUnixTimestamp,
   formatNumber,
   getUnixTimestampRange,
   getESTDate,
   intervalMap,
   filters,
   getThreeMonthsAgo,
   getMarketStatus,
   filterHighMap,
   isOldCompany,
   getLastValidTradingDay,
   timespanToMilliseconds,
} from '../helper/MarketsHelper';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import config from '../config';
import Select from 'react-select';
import { customStylesPlus } from '../helper/StylesHelper';
import { CircularProgress, Dialog, DialogActions, DialogTitle, Button, TextField } from '@mui/material';

export default function Stock() {
   const navigate = useNavigate();
   const { ticker } = useParams();
   const {isAuthenticated, userToken} = useContext(AuthContext);
   const [stockData, setStockData] = useState([]);
   const [dailyData, setDailyData] = useState({});  // Store daily open/close data
   const [filter, setFilter] = useState('1d');
   const [currentPrice, setCurrentPrice] = useState(null);
   const [changeAmount, setChangeAmount] = useState(null);
   const [changePercent, setChangePercent] = useState(null);
   const [stockName, setStockName] = useState(ticker); // Use ticker as placeholder name
   const [companyDetails, setCompanyDetails] = useState({});
   const [companyDescription, setCompanyDescription] = useState('');
   const [eps, setEps] = useState(null); // Store EPS
   const [dividendYield, setDividendYield] = useState(null); // Store dividend yield
   const [peRatio, setPERatio] = useState(null); // Store P/E Ratio
   const [periodHigh, setPeriodHigh] = useState(null); // Store 52-week high
   const [dateIPO, setDateIPO] = useState(null); // Store IPO date
   const [mainStockDataFetched, setMainStockDataFetched] = useState(false);
   const [companyDetailsFetched, setCompanyDetailsFetched] = useState(false);
   const [listOptions, setListOptions] = useState([]);
   const [selectedLists, setSelectedLists] = useState([]);
   const chartRef = useRef(null);
   const chartInstance = useRef(null); // Store the chart instance
   const [referencePriceForChart, setReferencePriceForChart] = useState(null);
   const [referencePrice1d , setReferencePrice1d] = useState(null);
   const [marketStatus, setMarketStatus] = useState(null);
   const [dailyChangeData, setDailyChangeData] = useState({});  // Store daily change data
   const [lastValidTradingDay, setLastValidTradingDay] = useState(null);
   const [livePrice, setLivePrice] = useState(null);    // This is the live price from the WebSocket
   const [liveChangeAmount, setLiveChangeAmount] = useState(null);  
   const [liveChangePercent, setLiveChangePercent] = useState(null);

    const [hoveredPrice, setHoveredPrice] = useState(null);
    const [hoveredChangeAmount, setHoveredChangeAmount] = useState(null);
    const [hoveredChangePercent, setHoveredChangePercent] = useState(null);
    const [hoveredDateTime, setHoveredDateTime] = useState(null);
    const [isHovering, setIsHovering] = useState(false);
    const [openBuyModal, setOpenBuyModal] = useState(false); // To handle modal state
    const [openSellModal, setOpenSellModal] = useState(false); // To handle modal state
 
    const wsRef = useRef(null); // Store WebSocket connection
    const instantPriceTimeoutRef = useRef(null); // Store timeout reference for instant price updates

    const [quantity, setQuantity] = useState(1); // Default quantity is 1
    const [estimatedCost, setEstimatedCost] = useState(null); //also used as credit for sell modal

    //eslint-disable-next-line
    const [bidAsk, setBidAsk] = useState({ bid: null, ask: null }); // Store bid and ask prices


    const [userPortfolio, setUserPortfolio] = useState(null); 
    const [userTickerPosition, setUserTickerPosition] = useState(null); // Store the user's position in the stock
    const [allTransactions, setAllTransactions] = useState([]); // Store all transactions for the stock
    const [todaysReturn, setTodaysReturn] = useState(null);
    const [allTimeReturn, setAllTimeReturn] = useState(null); 




    const handleQuantityChange = (event) => {
      const newQuantity = parseFloat(event.target.value); // Allow fractional shares
      setQuantity(newQuantity);
      setEstimatedCost((newQuantity * ( livePrice || currentPrice) ).toFixed(2)); // Update the estimated cost
    };


    const fetchUserPortfolio = async () => {  
      if (!isAuthenticated) {
        return;
      }

      try {
        const response = await axios.get(`${config.backendUrl}/api/stockActions/portfolio`, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
        setUserPortfolio(response.data);
    
      } catch (error) {
        console.error('Error fetching user portfolio:', error);
      }
    };


    const fetchAllTransactions = async () => {  
      if (!isAuthenticated) {
        return;
      }
      try {
        const response = await axios.get(`${config.backendUrl}/api/stockActions/transactions/${ticker}`, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
        setAllTransactions(response.data);
      } catch (error) {
        console.error('Error fetching user transactions:', error);
      }
    };

    const calculateTodaysReturn = (startPrice, livePrice, allTransactions, userTickerPosition) => {
      let todaysTransactions = allTransactions.filter((transaction) => {
        // Get the transaction date in EST
        const transactionDate = new Date(
          new Intl.DateTimeFormat('en-US', {
            timeZone: 'America/New_York',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          }).format(new Date(transaction.date))
        );
        // Get today's date in EST
        const today = new Date(
          new Intl.DateTimeFormat('en-US', {
            timeZone: 'America/New_York',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          }).format(new Date())
        );
    
        // Compare year, month, and date for equality
        return (
          transactionDate.getDate() === today.getDate() &&
          transactionDate.getMonth() === today.getMonth() &&
          transactionDate.getFullYear() === today.getFullYear()
        );
      });
    
      if (todaysTransactions.length === 0) {
        // No transactions today, calculate unrealized gain based on existing holdings
        return {
          totalReturn: (userTickerPosition?.quantity * (livePrice - startPrice)).toFixed(2),
          percentageGain: (((livePrice - startPrice) / startPrice) * 100).toFixed(2),
        };
      } else {
        // Transactions occurred today, adjust calculations accordingly
    
        let totalQuantityAtStartOfDay = 0; // Quantity held at the start of the day
        let totalCostAtStartOfDay = 0; // Cost basis for shares held at the start of the day
        let totalQuantityToday = 0; // Net quantity change during the day
        let totalCostToday = 0; // Cost basis for transactions during the day
    
        // Get the start of the day in EST
        const estTimeZone = 'America/New_York';
        const startOfDayEST = new Date(
          new Intl.DateTimeFormat('en-US', {
            timeZone: estTimeZone,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }).format(new Date())
        ).setHours(0, 0, 0, 0); // Start of the day at 00:00 EST
    
        // Loop through all transactions to separate before and during today
        allTransactions.forEach((transaction) => {
          const { type, quantity, price, date } = transaction;
          const transactionTime = new Date(date).getTime(); // Use the transaction date directly
    
          // Transactions before the start of the day
          if (transactionTime < startOfDayEST) {
            if (type === 'buy') {
              totalQuantityAtStartOfDay += quantity;
              totalCostAtStartOfDay += quantity * price;
            } else if (type === 'sell') {
              totalQuantityAtStartOfDay -= quantity;
              totalCostAtStartOfDay -= quantity * price;
            }
          } else {
            // Transactions during today
            if (type === 'buy') {
              totalQuantityToday += quantity;
              totalCostToday += quantity * price;
            } else if (type === 'sell') {
              totalQuantityToday -= quantity;
              totalCostToday -= quantity * price;
            }
          }
        });
    
        // Total quantities and costs
        const totalCost = totalCostAtStartOfDay + totalCostToday;
    
        // Calculate unrealized gain for shares held at the start of the day
        const unrealizedGainStart =
          totalQuantityAtStartOfDay > 0
            ? (livePrice - startPrice) * totalQuantityAtStartOfDay
            : 0;
    
        // Calculate unrealized gain for shares bought today
        const averageCostToday =
          totalQuantityToday !== 0 ? totalCostToday / totalQuantityToday : 0;
        const unrealizedGainToday =
          totalQuantityToday > 0
            ? (livePrice - averageCostToday) * totalQuantityToday
            : 0;
    
        // Total unrealized gain
        const totalUnrealizedGain = unrealizedGainStart + unrealizedGainToday;
    
        // Percentage gain based on total cost
        const percentageGain =
          totalCost > 0 ? (totalUnrealizedGain / totalCost) * 100 : 0;
    
        return {
          totalReturn: totalUnrealizedGain.toFixed(2),
          percentageGain: percentageGain.toFixed(2),
        };
      }
    };
  

    const calculateAllTimeReturn = (livePrice, userTickerPosition) => {
      const avgBuyPrice = userTickerPosition?.avgBuyPrice || 0;
      const totalQuantity = userTickerPosition?.quantity || 0; 
      const totalCost = avgBuyPrice * totalQuantity;
      const currentValue = livePrice * totalQuantity;
      const totalReturn = currentValue - totalCost;
      const percentageGain = totalCost > 0 ? (totalReturn / totalCost) * 100 : 0;
      return {
        totalReturn: totalReturn.toFixed(2),
        percentageGain: percentageGain.toFixed(2)
      };
   
    };

    useEffect(()=> {
      if ((livePrice || currentPrice) && allTransactions && userTickerPosition) {
        const returnData = calculateAllTimeReturn((livePrice || currentPrice), userTickerPosition);
        setAllTimeReturn(returnData);
      }
      if ((livePrice || currentPrice) && referencePrice1d && allTransactions) {
        const returnData = calculateTodaysReturn(referencePrice1d, (livePrice || currentPrice), allTransactions, userTickerPosition);
        setTodaysReturn(returnData);
      }


    }, [livePrice, currentPrice, allTransactions, referencePrice1d, userTickerPosition])

    useEffect(()=> {
      if (userPortfolio && ticker) {
       setUserTickerPosition(userPortfolio?.assets.find((stock) => stock?.ticker?.toUpperCase() === ticker?.toUpperCase()));
      }
    }, [userPortfolio, ticker]);


    useEffect(()=> {
      if (userToken) {
        fetchUserPortfolio();
        fetchAllTransactions();
      }
      //eslint-disable-next-line
    }, [userToken]);







    useEffect(()=> {
      // Calculate the estimated cost when the price changes
      if ((livePrice || currentPrice) ) {
        setEstimatedCost((quantity * (livePrice || currentPrice) ).toFixed(2));
      }
    }, [livePrice, quantity, currentPrice]);


    const updateChanges = (newPrice) => {
      if (!referencePriceForChart) {
        return;
      }
      setLivePrice(newPrice.toFixed(2));
      const change = newPrice - referencePriceForChart;
      const changePercentage = (change / referencePriceForChart) * 100;
      if (filter === '1d') {
            let priceChange;
            if (dailyChangeData?.todaysClosePrice) {
              priceChange = dailyChangeData.todaysClosePrice - dailyChangeData?.prevClosePrice;
              setLiveChangeAmount(priceChange.toFixed(2));
              setLiveChangePercent(dailyChangeData?.todaysPercentChange.toFixed(2));
            } else {
              priceChange = (livePrice || currentPrice) - dailyChangeData?.prevClosePrice;
              setLiveChangeAmount(priceChange.toFixed(2));
              setLiveChangePercent(changePercentage.toFixed(2));
            }
      } else {
        setLiveChangeAmount(change.toFixed(2));
        setLiveChangePercent(changePercentage.toFixed(2));
      }
    }

    useEffect(() => {
      // Function to fetch the instant price
      const fetchPrice = () => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
          wsRef.current.send(JSON.stringify({ action: 'getInstantPrice', ticker }));
        }
      };
    
      // Set interval to fetch the price every second
      const intervalId = setInterval(fetchPrice, 1000);
    
      // Cleanup the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }, [ticker]); 

    const handleBuyClick = () => {
      setOpenBuyModal(true);
    };

    const handleSellClick = () => { 
      setOpenSellModal(true);
    };


    // Function to close the buy modal
    const handleCloseModal = () => {
      setOpenBuyModal(false);
      setOpenSellModal(false);
  
      // Clear the timeout when the modal is closed
      if (instantPriceTimeoutRef.current) {
        clearTimeout(instantPriceTimeoutRef.current);
      }
    };
  

   const sendInterval = (intervalMs) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      console.log('Sending setInterval action to server:', intervalMs);
      wsRef.current.send(JSON.stringify({
        action: 'setInterval',
        ticker,
        intervalMs
      }));
    } else {
      console.log('WebSocket not ready, trying again...');
    }
  };

    useEffect(() => {
    
      // Retry every 500ms until the connection is open
      const interval = setInterval(() => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
          const [multiplier, timespan] = intervalMap[filter];
          const intervalMs = timespanToMilliseconds(multiplier, timespan);
          sendInterval(intervalMs);
          clearInterval(interval); // Stop retrying once it's sent
        }
      }, 500);

      //eslint-disable-next-line
    }, [filter, ticker]);




  
   useEffect(() => {
       getMarketStatus().then((data) => {
           setMarketStatus(data);
       });
   }, []);





    // Fetch stock lists
    const fetchStockLists = async () => {
       if (!isAuthenticated) {
         return;
       }
       try {
         const response = await axios.get(`${config.backendUrl}/api/polygon/stocklists`, {
           headers: { Authorization: `Bearer ${userToken}` },
         });
          const allLists = response.data;
          // Lists where the stock is already in
         const selectedStockLists = allLists.filter(list =>
           list.stocks.some(stock => stock.ticker.toLowerCase() === ticker.toLowerCase())
         );
          // Lists where the stock is NOT in (for adding the stock)
         const availableStockLists = allLists.filter(list =>
           !list.stocks.some(stock => stock.ticker.toLowerCase() === ticker.toLowerCase())
         );
          // Populate options and pre-select the ones containing the stock
         setListOptions(availableStockLists.map(list => ({ value: list._id, label: list.name })));
         setSelectedLists(selectedStockLists.map(list => ({ value: list._id, label: list.name })));
       } catch (error) {
         console.error('Error fetching stock lists:', error);
       }
     };


     const handleAddToList = async (listId, name) => {
       if (!isAuthenticated) {
           return;
       }
       try {
         const response = await axios.post(
           `${config.backendUrl}/api/polygon/stocklists/${listId}/stocks`,
           { ticker },
           {
             headers: {
               Authorization: `Bearer ${userToken}`,
             },
           }
         );
    
         if (response.status === 200) {
           alert(`${ticker} added to list "${name}"`);
           fetchStockLists();  // Re-fetch the lists to update the options after adding
         } else {
           console.error("Failed to add stock to the list");
         }
       } catch (error) {
         console.error("Error adding stock to list:", error);
       }
     };


     const handleRemoveFromList = async (listId, name) => {
       if (!isAuthenticated) {
           return;
       }
       try {
         const response = await axios.delete(
           `${config.backendUrl}/api/polygon/stocklists/${listId}/stocks/${ticker}`,
           {
             headers: {
               Authorization: `Bearer ${userToken}`,
             },
           }
         );
    
         if (response.status === 200) {
           alert(`${ticker} removed from list "${name}"`);
           fetchStockLists();  // Re-fetch the lists to update the options after removing
         } else {
           console.error("Failed to remove stock from the list");
         }
       } catch (error) {
         console.error("Error removing stock from list:", error);
       }
     }


     const getDailyChange = async () => {
       try {
           const response = await axios.get(`${config.backendUrl}/api/polygon/dailychange?ticker=${ticker}`);
           const data = response.data;
           setCurrentPrice(data.currentPrice.toFixed(2));
           setDailyChangeData(data);
       } catch (error) {
           console.error('Error fetching daily change:', error);
       }
   };






     useEffect(()=> {
       getDailyChange();
       // eslint-disable-next-line
     },[ticker]);








     useEffect(() => {
       if (marketStatus) {
           getLastValidTradingDay(marketStatus)
               .then((tradingDay) => {
                   setLastValidTradingDay(tradingDay);
               })
               .catch((error) => {
                   console.error("Error fetching the last valid trading day:", error);
               });
       }
   }, [marketStatus]);






   useEffect(()=> {
       fetchStockLists();
       // eslint-disable-next-line
   }, [userToken])




   const handleBackClick = () => {
       navigate('/markets');


   };


   // Fetch daily open/close data
   const fetchDailyOpenClose = async (date) => {
    
       try {
           const response = await axios.get(
               `${config.backendUrl}/api/polygon/daily-open-close`,  // Call your backend API
               {
                   params: {
                       ticker,  // Pass ticker as a query parameter
                       date     // Pass date as a query parameter
                   }
               }
           );
           setDailyData(response.data);  // Store daily data in state
       } catch (error) {
           console.error('Error fetching daily open/close data from backend:', error);
       }
   };


   // Fetch EPS and calculate P/E ratio
   const fetchEPSAndPERatio = async () => {
       try {
           // Make a request to your backend API
           const response = await axios.get(`${config.backendUrl}/api/polygon/stock/${ticker}/eps`);
  
           const { eps, peRatio } = response.data;
  
           // Set the EPS value in state
           setEps(eps);
          
           // Set the P/E ratio if available
           if (peRatio) {
               setPERatio(peRatio);
           }
       } catch (error) {
           console.error('Error fetching EPS or P/E ratio:', error);
       }
   };
   const fetchDividendYield = async () => {
       try {
           const response = await axios.get(
               `${config.backendUrl}/api/polygon/stock/${ticker}/dividend-yield`  // Call the backend API
           );
        
           const dividendData = response.data;
  
           if (dividendData.dividendYield !== 'N/A') {
               // Calculate Dividend Yield
               if (currentPrice) {
                   const yieldValue = (dividendData.dividendYield / currentPrice) * 100;
                   setDividendYield(yieldValue?.toFixed(2));
               }
           } else {
               setDividendYield('N/A');
           }
        
       } catch (error) {
           console.error('Error fetching dividend yield from backend:', error);
           setDividendYield('N/A');
       }
   };


   const fetchCompanyDetails = async () => {
       try {
           const companyResponse = await axios.get(
               `${config.backendUrl}/api/polygon/stock/${ticker}/company-details` // Call your backend API
           );
           const companyInfo = companyResponse.data;
           setDateIPO(companyInfo.listDate); // Store IPO date
           setStockName(companyInfo.name || ticker); // Replace stockName with company name or fallback to ticker
           setCompanyDetails({
               marketCap: companyInfo.marketCap,
           });
           setCompanyDescription(companyInfo.description || 'No description available.');
       } catch (error) {
           console.error('Error fetching company details from backend:', error);
       }
  
       setCompanyDetailsFetched(true);
   };


  


   const fetchStockData = async () => {
       setChangeAmount(null);
       setPeriodHigh(null);
       setMainStockDataFetched(false);
       setLiveChangeAmount(null);
       setLiveChangePercent(null);
       setLivePrice(null);
       try {
           let fromDateUnixMs;
           let multiplier, timespan;
           let referencePrice;
           let toDateUnixMs = getUnixTimestamp();
  
           if (filter === 'all time') {
               // For all-time filter, use the date of the IPO or earliest available stock data
               fromDateUnixMs = dateIPO || getESTDate();
               const earliestAvailableDateStr = '2003-08-01'; // Earliest available date for stock data
               if (new Date(fromDateUnixMs) < new Date(earliestAvailableDateStr)) {
                   fromDateUnixMs = earliestAvailableDateStr;
               }
           } else if (filter === '3m') {
               fromDateUnixMs = getThreeMonthsAgo();
           } else if (filter === '1d') {
               // Get the current date/time in ET
               const now = new Date();
               const options = {
                   timeZone: 'America/New_York',
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                   hour: 'numeric',
                   hour12: false,
                   timeZoneName: 'short'
               };
               const formatter = new Intl.DateTimeFormat('en-US', options);
               const parts = formatter.formatToParts(now);
          
               let year, month, day, timeZoneName;
               parts.forEach(({ type, value }) => {
                   if (type === 'year') year = value;
                   if (type === 'month') month = value;
                   if (type === 'day') day = value;
                   if (type === 'timeZoneName') timeZoneName = value;
               });
          
               // Determine UTC offset based on daylight saving time
               const isDST = timeZoneName.includes('EDT');
               const offset = isDST ? 4 : 5; // ET is UTC-5, EDT is UTC-4
          
               const dateInET = new Date(Date.UTC(year, month - 1, day, 9 + offset, 30, 0));


               // Check if the day is a weekend
               if (dateInET.getDay() === 6 || dateInET.getDay() === 0) { // 6 = Saturday, 0 = Sunday
                   const daysToSubtract = dateInET.getDay() === 6 ? 1 : 2; // If Saturday, subtract 1 day. If Sunday, subtract 2 days.
                   // Set the date to Friday (by subtracting the appropriate number of days)
                   dateInET.setDate(dateInET.getDate() - daysToSubtract);
                   // Set time to 9:30 AM ET for Friday
                   dateInET.setUTCHours(9 + offset, 30, 0, 0); // Reset time to 9:30 AM ET


               }
               fromDateUnixMs = dateInET.getTime();
               toDateUnixMs = fromDateUnixMs + 23400000; // Add 6.5 hours to get 4:00 PM ET
               referencePrice = dailyChangeData?.prevClosePrice;
               setReferencePrice1d(referencePrice);
           } else if (filter === '1w') {
               // Get the current date/time in ET
               const now = new Date();
               const options = {
                   timeZone: 'America/New_York',
                   weekday: 'short',
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                   timeZoneName: 'short'
               };
               const formatter = new Intl.DateTimeFormat('en-US', options);
               const parts = formatter.formatToParts(now);
          
               let year, month, day, weekday, timeZoneName;
               parts.forEach(({ type, value }) => {
                   if (type === 'year') year = value;
                   if (type === 'month') month = value;
                   if (type === 'day') day = value;
                   if (type === 'weekday') weekday = value;
                   if (type === 'timeZoneName') timeZoneName = value;
               });
          
               // Determine UTC offset based on daylight saving time
               const isDST = timeZoneName.includes('EDT');
               const offset = isDST ? 4 : 5; // ET is UTC-5, EDT is UTC-4
          
               // Calculate the number of days since Monday
               const daysOfWeek = { Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6 };
               const currentDayOfWeek = daysOfWeek[weekday];
               const daysSinceMonday = (currentDayOfWeek + 6) % 7; // Adjust so Monday is 0
              
               // Calculate the date for last Monday
               const dateInET = new Date(Date.UTC(year, month - 1, day - daysSinceMonday, 9 + offset, 30, 0));
               fromDateUnixMs = dateInET.getTime();
               toDateUnixMs = fromDateUnixMs + 369000000;
           } else {
               // Other filters: use standard logic
               fromDateUnixMs = getUnixTimestampRange(filter);
           }
  
           const intervalArray = intervalMap[filter];
           multiplier = intervalArray[0];
           timespan = intervalArray[1];
          
           if (filter === 'all time' || filter === '3m') {
               toDateUnixMs = getESTDate(); //use YYYY-MM-DD format for all time and 3m filters
           }


           const response = await axios.get(`${config.backendUrl}/api/polygon/stock/datapoints`, {
               params: {
                   ticker,
                   fromDateUnixMs,
                   toDateUnixMs,
                   multiplier,
                   timespan
               }
           });
           const data = response.data || [];
           setStockData(data);
  
           if (data.length > 0) {
               const latestPrice =  livePrice || currentPrice || data[data.length - 1].c;
               if (referencePrice) {
                   const change = latestPrice - referencePrice;
                   const changePercentage = (change / referencePrice) * 100;
                   if (filter === '1d') { 
                       let priceChange =
                       ((dailyChangeData?.todaysClosePrice ? dailyChangeData.todaysClosePrice : (livePrice ||  currentPrice))
                       - dailyChangeData?.prevClosePrice);
                       setChangeAmount(priceChange.toFixed(2));
                       setChangePercent(dailyChangeData.todaysPercentChange.toFixed(2));
                   } else {
                        setChangeAmount(change.toFixed(2));
                        setChangePercent(changePercentage.toFixed(2));
                   }
               } else {
                   // Fallback if referencePrice is not available
                   const startPrice = data[0].c;
                   const change = latestPrice - startPrice;
                   const changePercentage = (change / startPrice) * 100;
                   setChangeAmount(change.toFixed(2));
                   setChangePercent(changePercentage.toFixed(2));
               }
  
               const highPrices = data.map(day => day.h);
               const highestPrice = Math.max(...highPrices);
               setPeriodHigh(highestPrice);
               setReferencePriceForChart(referencePrice || data[0].c);
           } else {
               setChangeAmount(null);
               setChangePercent(null);
               setPeriodHigh(null);
           }
       } catch (error) {
           console.error('Error fetching stock data:', error);
       }
       setMainStockDataFetched(true);
   };






   // company details -> stock data -> stock statistics needs to be fetched in order
   useEffect(() => {
       fetchCompanyDetails();
       // eslint-disable-next-line
   }, [ticker]);

   
  

   useEffect(() => {
       if (!companyDetailsFetched || !currentPrice) {
           return;
       }
       fetchStockData();
       // eslint-disable-next-line
   }, [ticker, filter, dateIPO, companyDetailsFetched, currentPrice]);


   useEffect(() => {
       if (!mainStockDataFetched) {
           return;
       }
       fetchDividendYield();
       fetchEPSAndPERatio();
      
  //eslint-disable-next-line
   }, [ticker, mainStockDataFetched]);


  



   useEffect(() => {
       if (lastValidTradingDay) {
           fetchDailyOpenClose(lastValidTradingDay);
       }
       //eslint-disable-next-line
   }, [lastValidTradingDay]);







   useEffect(() => {
    if (chartRef.current) {
      // Destroy existing chart instance to avoid memory leaks
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
  
      const ctx = chartRef.current.getContext('2d');
  
      // Prepare labels and data
      const labels = stockData.map((data) => {
        const date = new Date(data.t);
        const options = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
      });
  
      const prices = stockData.map((data) => data.c);
  
      const referencePrice = referencePriceForChart || (stockData.length > 0 ? stockData[0].c : 0);
  
      // Plugin to draw horizontal line at reference price
      const horizontalLinePlugin = {
        id: 'horizontalLinePlugin',
        beforeDraw: (chart) => {
          if (chart.tooltip?._active?.length) return;
          const { ctx, chartArea: { right, left }, scales: { y } } = chart;
          ctx.save();
          ctx.strokeStyle = '#C6C6C6';
          ctx.setLineDash([5, 5]);
          ctx.beginPath();
          ctx.moveTo(left, y.getPixelForValue(referencePrice));
          ctx.lineTo(right, y.getPixelForValue(referencePrice));
          ctx.stroke();
          ctx.restore();
        },
      };
  
      // Plugin to draw vertical line when hovering
      const verticalLinePlugin = {
        id: 'verticalLinePlugin',
        afterDatasetsDraw: function (chart) {
          if (chart.tooltip && chart.tooltip.getActiveElements().length > 0) {
            const ctx = chart.ctx;
            const activePoint = chart.tooltip.getActiveElements()[0];
            const x = activePoint.element.x;
            const topY = chart.chartArea.top;
            const bottomY = chart.chartArea.bottom;
  
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = '#C6C6C6'; // Same styling as the horizontal line
            ctx.setLineDash([5, 5]);
            ctx.stroke();
            ctx.restore();
          }
        },
      };

      
  
      // Create new chart instance
      chartInstance.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels,
          spanGaps: true,
          datasets: [{
            data: prices,
            borderColor: ( changeAmount) >= 0.00 ? '#1DCF5B' : '#ff6347',
            fill: false,
            borderWidth: 2,
            pointRadius: function(context) {
              const index = context.dataIndex;
              const dataset = context.dataset.data;
              return index === dataset.length - 1 ? 2 : 0;  // Set radius 5 for the last point, 0 for others
            },
            pointHoverRadius: 5, // Show points when hovered
            pointBackgroundColor: (changeAmount) >= 0.00 ? '#1DCF5B' : '#ff6347',
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            y: {
              duration: 0,  // Disable animation for y-axis changes
            },
          },
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
          },
          hover: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
          },
          scales: {
            x: {
              display: false,
              grid: { display: false },
              border: {
                color: '#fff',
                display: true,
              },
            },
            y: {
              display: false,
              grid: { display: false },
              border: {
                color: '#fff',
                display: true,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              mode: 'nearest',
              intersect: false,
              external: function (context) {
                const { tooltip } = context;
  
                if (tooltip.dataPoints) {
                  const dataPoint = tooltip.dataPoints[0];
                  const index = dataPoint.dataIndex;
                  const price = dataPoint.parsed.y;
  
                  // Calculate change amount and percentage from reference price
                  const referencePrice = referencePriceForChart || stockData[0]?.c;
                  const changeAmount = price - referencePrice;
                  const changePercent = ((changeAmount / referencePrice) * 100).toFixed(2);
  
                  // Update state variables
                  setHoveredPrice(price.toFixed(2));
                  setHoveredChangeAmount(changeAmount.toFixed(2));
                  setHoveredChangePercent(changePercent);
                  setHoveredDateTime(labels[index]);
                }
              },
            },
          },
          font: {
            family: 'SF Pro Display Medium',
          },
        },
        plugins: [horizontalLinePlugin, verticalLinePlugin],
      });
  
   
  
      // Add event listener for mouse leave
      const canvas = chartInstance.current.canvas;
      const handleMouseLeave = () => {
        setIsHovering(false);
      };
      const handleMouseEnter = () => {
        setIsHovering(true);
      };
      canvas.addEventListener('mouseleave', handleMouseLeave);
      canvas.addEventListener('mouseenter', handleMouseEnter);
  
      // Clean up the event listener and destroy the chart on unmount or re-render
      return () => {
        canvas.removeEventListener('mouseleave', handleMouseLeave);
        canvas.removeEventListener('mouseenter', handleMouseEnter);
        chartInstance.current.destroy();
      };
    }
  }, [
    stockData,
    chartRef,
    filter,
    ticker,
    referencePriceForChart,
    changeAmount,
  ]);


// WebSocket connection for real-time updates
useEffect(() => {
    if (!referencePriceForChart || !chartRef.current) {
      return;
    }
  
    const ws = new WebSocket(config.socketUrl);
  
    ws.onopen = () => {
      console.log('Connected to WebSocket server');
      ws.send(JSON.stringify({ action: 'subscribe', ticker }));
    };
  
    ws.onmessage = (event) => {
      let message = JSON.parse(event.data);

      if (message.type === 'instantPrice') {
        const data = message.data[0];
        if (data && data.ev === 'T' && data.sym === ticker.toUpperCase()) {
          const tradePrice = data.p;
          updateChanges(tradePrice);
        }

        const dataBidAsk = message.data[1];
        if (dataBidAsk && dataBidAsk.ev === 'Q' && dataBidAsk.sym === ticker.toUpperCase()) {
          const bidPrice = dataBidAsk.bp;
          const askPrice = dataBidAsk.ap;
          setBidAsk({ bid: bidPrice, ask: askPrice });
        }
        
      } else {
          let data = JSON.parse(event.data);
          data = data[0]; // Extract the data object from the array
          if (data.ev === 'T' && data.sym === ticker.toUpperCase()) {
            const tradePrice = data.p; // New price from WebSocket
            const tradeTime = data.t; // New timestamp from WebSocket
      
            // Update chart by adding new data point
            const newPoint = {
              t: tradeTime, // Unix timestamp from WebSocket
              c: tradePrice.toFixed(2), // Price from WebSocket
            };

      
      
            // Add new label and data point to the chart
            if (chartInstance.current) {
              chartInstance.current.data.labels.push(
                new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                }).format(new Date(newPoint.t))
              );

              chartInstance.current.data.datasets[0].data.push(newPoint.c);
      
              // Calculate change from reference price
              const change = newPoint.c - referencePriceForChart;

      
              // Dynamically update chart colors based on liveChangeAmount
              const borderColor = change >= 0 ? '#1DCF5B' : '#ff6347'; // Green for positive, red for negative
              const pointBackgroundColor = change >= 0 ? '#1DCF5B' : '#ff6347'; // Green for positive, red for negative
      
              // Update chart dataset colors
              chartInstance.current.data.datasets[0].borderColor = borderColor;
              chartInstance.current.data.datasets[0].pointBackgroundColor = pointBackgroundColor;

                if (chartRef.current && chartInstance.current) {
                  chartInstance.current.update({
                    duration: 100,      // Set animation duration
                    lazy: true,        
                    easing: 'easeInOutBounce',  // Use a custom easing function
                  });
                }
            }
          }
       }
    };
  
    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };
  
    wsRef.current = ws;
  
    return () => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(JSON.stringify({ action: 'unsubscribe', ticker }));
        wsRef.current.close();
      }
    };
    //dont include currentPrice/liveprice in the dependencies array because that will cause a re-render loop 
    //eslint-disable-next-line
  }, [ticker, filter, dailyChangeData, referencePriceForChart, liveChangeAmount]);

       const renderAfterMarketChange = () => {
           if (!marketStatus || ['1w', '1m', '3m', '1y', 'all time'].includes(filter)) {
               return null;
           }


           const { message } = marketStatus;
           let priceChange, priceChangePercent, duration;
           let price = livePrice || dailyChangeData?.currentPrice;
           let afterHoursPercentChange = (price - dailyChangeData?.todaysClosePrice)/dailyChangeData?.todaysClosePrice * 100;
           if (message === 'market is closed (After-Hours).') {
               priceChange = price - dailyChangeData?.todaysClosePrice;
               priceChangePercent = afterHoursPercentChange || dailyChangeData?.afterHoursPercentChange;
               duration = 'after-hours';
           } else if (message === 'market is closed.') {
               priceChange = price - dailyChangeData?.todaysClosePrice;
               priceChangePercent = afterHoursPercentChange || dailyChangeData?.afterHoursPercentChange;
               //duration = lastValidTradingDay === getESTDate() ? 'overnight-hours' : 'after-hours';
               //I dont think polygon has overnight-hours data
               duration = 'after-hours';
           } else if (message === 'market is closed (Pre-Market).') {
               priceChange = price - dailyChangeData?.prevClosePrice;
               priceChangePercent = (priceChange / dailyChangeData?.prevClosePrice) * 100;
               duration = 'pre-market';
           } else {
               return null; // Market is open
           }


           return (
               <p className={`StockChange bold ${priceChangePercent >= 0 ? 'positive' : 'negative'}`}>
                   {priceChangePercent >= 0 ? '+' : ''}
                   {priceChange?.toFixed(2)} ({priceChangePercent?.toFixed(2)}%) <span className='StockDuration'>
                       {duration}
                   </span>
               </p>
           );
       };

       const handleBuyAsset = async () => {
        if (!isAuthenticated) {
          alert('Please sign in to make a trade.');
          return;
        }
      
        try {
          const response = await axios.post(
            `${config.backendUrl}/api/stockActions/buy`,
            {
              ticker,
              quantity,
              price: livePrice || currentPrice, // Use the live price or the current price
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
      
          if (response.status === 201) {
            alert('Asset bought successfully!');
            setOpenBuyModal(false);
            fetchUserPortfolio();
            fetchAllTransactions();
          } else {
            alert('Error buying asset. Please try again.');
          }
        } catch (error) {
          console.error('Error buying asset:', error);
          alert(`Error: ${error.response?.data?.message || 'Failed to complete the transaction.'}`);
        }
      };
      
      const handleSellAsset = async () => {
        if (!isAuthenticated) {
          alert('Please sign in to make a trade.');
          return;
        }
      
        try {
          const response = await axios.post(
            `${config.backendUrl}/api/stockActions/sell`,
            {
              ticker,
              quantity,
              price: livePrice || currentPrice, // Use the live price or the current price
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
      
          if (response.status === 200) {
            alert('Asset sold successfully!');
            setOpenSellModal(false);
            fetchUserPortfolio();
            fetchAllTransactions();
          } else {
            alert('Error selling asset. Please try again.');
          }
        } catch (error) {
          console.error('Error selling asset:', error);
          alert(`Error: ${error.response?.data?.message || 'Failed to complete the transaction.'}`);
        }
      };



   return (
       <div className='StockContainerWrapper' style={{ display: 'flex', flexDirection: 'column' }}>
           <div className="StockContainer">
               <div className="pr-top-bar">
                   <div className="pr-feed-wrapper" onClick={handleBackClick} style={{ cursor: 'pointer' }}>
                       <FontAwesomeIcon icon={faArrowLeft} className="pr-back-icon" />
                       <span className="pr-title bold">Back</span>
                   </div>
                   <span className="pr-username bold">@{ticker}</span>
               </div>


               <div className="MainStockContainer">
                   <div className="StockPriceContent">
                       <div className="StockGraph">
                           <div className="StockHeader">
                               <div className="StockInfo">
                                   <h1 className="StockName bold">{stockName}</h1>
                                   <p className="StockPrice bold">
                    ${isHovering ? hoveredPrice : (livePrice || currentPrice)}
                  </p>
                  <p
                    className={`StockChange bold ${
                      (isHovering ? hoveredChangeAmount : (liveChangeAmount || changeAmount)) >= 0 ? 'positive' : 'negative'
                    }`}
                  >
                    {isHovering ? (
                      <>
                        {hoveredChangeAmount >= 0.00 ? '+' : ''}
                        {hoveredChangeAmount} ({hoveredChangePercent}%)
                      </>
                    ) : (liveChangeAmount || changeAmount) !== null ? (
                      <>
                        {(liveChangeAmount || changeAmount) >= 0.00 ? '+' : ''}
                        {(liveChangeAmount || changeAmount)} ({(liveChangePercent || changePercent)}%)
                      </>
                    ) : (
                      <span style={{ color: '#b3b3b3' }}>--</span>
                    )}{' '}
                    <span className="StockDuration">
                      {isHovering
                        ? hoveredDateTime
                        : filters[filter] === 'all time'
                          ? (
                            <>
                              {filters[filter]} {isOldCompany(dateIPO) && '(since 8/30/2003)'}
                            </>
                          )
                          : filters[filter] === 'live' ? 'live' :`past ${filters[filter]}`
                      }
                    </span>
                  </p>
                          
                                   {!isHovering && renderAfterMarketChange()}
                               

                             
                                   <div className="StockActions">
                                   <button onClick= {handleBuyClick} className="CreatePostFeedButton">Buy</button> 
                                   <button onClick = {handleSellClick} className="CreatePostFeedButton">Sell</button> 
                                   <Select
                                   options={listOptions}
                                   value={selectedLists} // Pre-select lists containing the stock
                                   placeholder="Add to list +"
                                   isClearable={false}
                                   isSearchable={false}
                                   unstyled
                                   isMulti={true}
                                   styles={customStylesPlus}
                                   onMenuOpen={() => {
                                       if (!isAuthenticated) {
                                               alert('Please sign in to add stocks to lists.');
                                               return;
                                       }
                                      fetchStockLists()}
                                   }
                                   onChange={(selectedOptions) => {
                                       // Check if a list was removed
                                       if (selectedOptions.length < selectedLists.length) {
                                       const removedList = selectedLists.find(
                                           (selected) => !selectedOptions.some((option) => option.value === selected.value)
                                       );
                                       if (removedList) {
                                           handleRemoveFromList(removedList.value, removedList.label);  // Remove the stock from the selected list
                                       }
                                       }
                                      
                                       // Check if a list was added
                                       if (selectedOptions.length > selectedLists.length) {
                                       const addedList = selectedOptions.find(
                                           (option) => !selectedLists.some((selected) => selected.value === option.value)
                                       );
                                       if (addedList) {
                                           handleAddToList(addedList.value, addedList.label);  // Add the stock to the selected list
                                       }
                                       }


                                       // Update the selected lists after the change
                                       setSelectedLists(selectedOptions);
                                   }}
                                   />
                                    
                                   </div>
                               </div>
                           </div>
                           <div className="StockGraphMain">
                           {!mainStockDataFetched ? (
                       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50rem' }}>
                           <CircularProgress size="3rem" />
                       </div>
                   ) : (
                       <canvas ref={chartRef}></canvas>
                   )}
                               <div className="StockFilters">
                                   {Object.keys(filters).map((key) => (
                                       <button key={key} className={`stock-filter-btn ${filter === key ? 'selected' : 'not-selected'}`} onClick={() => setFilter(key)}>
                                           {key}
                                       </button>
                                   ))}
                               </div>


                               <div className="StockDescription">
                                   <h2 className="StockDescriptionHeader">Description</h2>
                                   <p>{companyDescription}</p>
                               </div>
                               <div className="StockStatistics" style ={{paddingBottom : !userTickerPosition  && '4rem' }}>
                            <h2 className="StatsHeader">Statistics</h2>
                            <table className="StatsTable">
                                <tbody style={{ width: '100%' }}>
                                    <tr>
                                        <td>Open</td>
                                        <td><strong>{dailyData?.open?.toFixed(2) || '--'}</strong></td>
                                        <td>Close</td>
                                        <td><strong>{dailyData?.close?.toFixed(2) || '--'}</strong></td>
                                        <td>Volume</td>
                                        <td><strong>{dailyData?.volume ? formatNumber(dailyData.volume) : '--'}</strong></td>
                                        <td>P/E Ratio</td>
                                        <td><strong>{peRatio || '--'}</strong></td> {/* P/E Ratio */}
                                      
                                    </tr>
                                    <tr>
                                        <td>High</td>
                                        <td><strong>{dailyData?.high?.toFixed(2) || "--"}</strong></td>
                                        <td>Low</td>
                                        <td><strong>{dailyData?.low?.toFixed(2) || "--"}</strong></td>
                                        <td style = {{textTransform: 'capitalize'}}>{filterHighMap[filter]} High</td>
                                        <td><strong>{periodHigh ? periodHigh?.toFixed(2) : '--'}</strong></td> {/* 52 Week High */}
                                        <td>EPS (TTM)</td>
                                        <td><strong>{eps?.toFixed(2) || '--'}</strong></td>
                                  
                                    </tr>
                                    <tr>
                                        <td>Pre-Market</td>
                                        <td><strong>{dailyData?.preMarket || "--"}</strong></td>
                                        <td>After-Hours</td>
                                        <td><strong>{dailyData?.afterHours || "--"}</strong></td>
                                        <td>Market Cap</td>
                                        <td><strong>{companyDetails?.marketCap ? formatNumber(companyDetails.marketCap) : '--'}</strong></td>
                                        <td>Dividend Yield %</td>
                                        <td><strong>{dividendYield} {dividendYield !== 'N/A' ? '%' : ''} </strong></td> {/* Dividend Yield */}
                                    
                                    </tr>
                                </tbody>
                            </table>
                               </div>
                                {/*Borrowing class names */}
                           {userTickerPosition &&    <div className="StockStatistics" style = {{paddingBottom :'4rem'}}>
                                <h2 className='StatsHeader'>Your Position</h2>
                                <table className="StatsTable" >
                            <tbody style={{ width: '100%' }}>
                              <tr>
                                <td>Shares</td>
                                <td><strong>{userTickerPosition?.quantity}</strong></td>
                                <td>Market Value</td>
                                <td><strong>{ (userTickerPosition?.quantity * (livePrice || currentPrice)).toFixed(2)}</strong></td>
                                <td>Dividends Earned</td>
                                <td><strong>{ '--'}</strong></td> {/* New column */}
                                <td>Today's Return</td>
                                <td>
                                  <strong>
                                    {todaysReturn ? (
                                      <>
                                        {todaysReturn.totalReturn >= 0 ? "+" : ""}
                                        {todaysReturn.totalReturn} ({todaysReturn.percentageGain}%)
                                      </>
                                    ) : '--'}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>Average Cost</td>
                                <td><strong>{userTickerPosition?.avgBuyPrice?.toFixed(2)}</strong></td>
                                <td>Portfolio Allocation</td>
                                <td><strong>{
                                ((userTickerPosition?.quantity * userTickerPosition?.avgBuyPrice)
                                /(userPortfolio?.totalValue) * 100).toFixed(2)}%</strong></td> 
                                <td>Cost Basis</td>
                                <td><strong>{allTransactions?.length > 0 && allTransactions?.reduce((acc, transaction) => { 
                                return transaction.type === 'buy' ? acc + (transaction.price * transaction.quantity) : acc;
                            }, 0)}</strong></td>
                                <td>Total Return</td>
                                <td>
                            <strong>
                            {allTimeReturn ? (
                                      <>
                                        {allTimeReturn.totalReturn >= 0 ? "+" : ""}
                                        {allTimeReturn.totalReturn} ({allTimeReturn.percentageGain}%)
                                      </>
                                    ) : '--'}
                            
                            </strong>
                          </td>
                              </tr>
                              
                            </tbody>
                          </table>
                               </div>}
                           </div>
                       </div>
                   </div>
               </div>


           </div>
{/* Buy Modal */}
<Dialog
  className="stock-dialog"
  open={openBuyModal}
  onClose={handleCloseModal}
  sx={{ 
    '& .MuiDialog-paper': {
      padding: '2rem',
      borderRadius: '1rem',
    }
  }}
>
  <DialogTitle 
  className="stock-dt"
  sx={{ fontSize: '1.39rem', paddingTop: '1rem', paddingBottom: '1rem' }}>
    Do you want to place a market order to buy <b>{ticker}</b> at <b>${(livePrice || livePrice || currentPrice) || '--'}</b>?
  </DialogTitle>

  <div 
  className="stock-dialog-div"  
  style={{ padding: '2rem' }}>
    <TextField
      label="Shares"
      type="number"
      slotProps={{
        input: {
          step: 'any',
        },
      }}
      value={quantity}
      onChange={handleQuantityChange}
      fullWidth
      margin="normal"
      sx={{ 
        fontSize: '1.39rem',
        '& .MuiInputBase-root': { fontSize: '1.39rem' },
        '& .MuiFormLabel-root': { fontSize: '1.39rem' }
      }}
    />
    <p className="stock-estimated-cost" style={{  fontSize: '1.39rem' }}>
      Estimated cost: <b>{isNaN(estimatedCost) ? '--' : `$${estimatedCost}`}</b>
    </p>
  </div>


  <DialogActions sx={{ justifyContent: 'space-between', padding: '1rem' }}>
    <Button onClick={handleCloseModal} sx={{ fontSize: '1.39rem', padding: '0.5rem 1.5rem' }}>Cancel</Button>
    <Button onClick={handleBuyAsset} color="primary" autoFocus sx={{ fontSize: '1.39rem', padding: '0.5rem 1.5rem' }}>Confirm Buy</Button>
  </DialogActions>



  </Dialog>
  

{/* Sell Modal */}
<Dialog 
className="stock-dialog"
  open={openSellModal} 
  onClose={handleCloseModal}
  sx={{ 
    '& .MuiDialog-paper': {
      padding: '2rem',
      borderRadius: '1rem',
    }
  }}
>
  <DialogTitle 
  className='stock-dt'
  sx={{ fontSize: '1.39rem', paddingTop: '1rem', paddingBottom: '1rem', fontFamily:`SF Pro Display Medium', sans-serif` }}>
     Do you want to place a market order to sell <b>{ticker}</b> at <b>${(livePrice || livePrice || currentPrice) || '--'}</b>?
  </DialogTitle>

  <div className="stock-dialog-div" style={{ padding: '2rem' }}>
    
    <TextField
      label="Shares"
      type="number"
      slotProps={{
        input: {
          step: 'any',
        },
      }}
      value={quantity}
      onChange={handleQuantityChange}
      fullWidth
      margin="normal"
      sx={{ 
        fontSize: '1.39rem',
        '& .MuiInputBase-root': { fontSize: '1.39rem' },
        '& .MuiFormLabel-root': { fontSize: '1.39rem' }
      }}
    />
    <p className="stock-estimated-cost" style={{  fontSize: '1.39rem'  }}>
      Estimated revenue: <b className='bold'>{isNaN(estimatedCost) ? '--' : `$${estimatedCost}`}</b>
    </p>
  </div>

  <DialogActions sx={{ justifyContent: 'space-between', padding: '1rem' }}>
    <Button onClick={handleCloseModal} sx={{ fontSize: '1.39rem', padding: '0.5rem 1.5rem' }}>Cancel</Button>
    <Button onClick={handleSellAsset} color="primary" autoFocus sx={{ fontSize: '1.39rem', padding: '0.5rem 1.5rem' }}>Confirm Sell</Button>
  </DialogActions>
</Dialog>
       </div>

   );
}