import axios from 'axios';
import config from '../config';
import { DateTime } from 'luxon';

// Helper function to format dates to YYYY-MM-DD in a given timezone
const formatDateInTimezone = (date, timeZone) => {
    const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: timeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    const parts = formatter.formatToParts(date);
    const year = parts.find(part => part.type === 'year').value;
    const month = parts.find(part => part.type === 'month').value;
    const day = parts.find(part => part.type === 'day').value;

    return `${year}-${month}-${day}`;  // Return formatted date as YYYY-MM-DD
};

export const getUnixTimestampRange = (filter) => {
    const currentDate = new Date();  // Get current time
    switch (filter) {
        case '1y':
            currentDate.setFullYear(currentDate.getFullYear() - 1);
            break;
        case '3m':
            currentDate.setMonth(currentDate.getMonth() - 3);
            break;
        case '1m':
            currentDate.setMonth(currentDate.getMonth() - 1);
            break;
        case '1w':
            currentDate.setDate(currentDate.getDate() - 7);
            break;
        case '1d':
            currentDate.setDate(currentDate.getDate() - 1);
            break;
        case '1h':
            currentDate.setHours(currentDate.getHours() - 1);
            break;
        case 'live':
            return Math.floor(currentDate.getTime() - 300000 ); // 5 minutes ago
        default:
            currentDate.setFullYear(currentDate.getFullYear() - 1);
            break;
    }
    // Return the Unix timestamp (in seconds)
    return Math.floor(currentDate.getTime() );
};

export const getUnixTimestamp = () => {
    return new Date().getTime();  // This gives you the timestamp in milliseconds
};

// Get the current date in EST
export const getESTDate = () => {
    const currentDate = new Date();
    return formatDateInTimezone(currentDate, 'America/New_York');  // Format date in EST
};

export const getHSTDate = () => {
    const currentDate = new Date();
    return formatDateInTimezone(currentDate, 'Pacific/Honolulu');  // Format date in HST
};  

export const getThreeMonthsAgo = () => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 3);
    return formatDateInTimezone(currentDate, 'America/New_York');
}

// Format large numbers (remains unchanged)
export const formatNumber = (value) => {
    if (value >= 1e12) {
        return (value / 1e12).toFixed(2) + 'T'; // Trillions
    } else if (value >= 1e9) {
        return (value / 1e9).toFixed(2) + 'B'; // Billions
    } else if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + 'M'; // Millions
    } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + 'K'; // Thousands
    } else {
        return value.toString(); // Less than a thousand
    }
};

export const getMarketStatus = async () => {
    try {
        const response = await axios.get(`${config.backendUrl}/api/polygon/marketstatus`);
        return response.data;
    } catch (error) {
        console.error('Error fetching market status:', error);
    }
};

export const filters = {
    'live': 'live',
    '1h': 'hour',
    '1d': 'day',
    '1w': 'week',
    '1m': 'month',
    '3m': '3 months',
    '1y': 'year',
    'all time': 'all time',
};

export const filterHighMap = {
    'live': 'Live',
    '1h': 'One Hour',
    '1d': 'One Day',
    '1w': 'One week',
    '1m': 'One month',
    '3m': 'Three month',
    '1y': 'One year',
    'all time': 'All time',
};

export const intervalMap = {
    'all time': ['1', 'week'],
    '1y': ['1', 'day'],      
    '3m': ['1', 'day'],     
    '1m': ['1', 'hour'],
    '1w': ['15', 'minute'],   
    '1d': ['1', 'minute'],     
    '1h': ['14', 'second'],  
    'live': ['1', 'second'],  
};

export const isOldCompany = (dateIPO) => {
    if (!dateIPO) return false;
    const referenceDate = new Date('2003-08-30');
    const ipoDate = new Date(dateIPO);
    return ipoDate <= referenceDate;
};







export const getLastValidTradingDay = async (marketStatus = null) => {
    try {
      // Fetch market status and get the server time
      if (!marketStatus) {
          marketStatus = await getMarketStatus();
      }
      const serverTime = marketStatus.serverTime;
  
      // Convert serverTime to a DateTime object in the "America/New_York" timezone
      let serverDate = DateTime.fromISO(serverTime, { zone: 'America/New_York' });
  
      // Get the current year and day of the week
      let currentYear = serverDate.year;
      let currentDay = serverDate.weekday; // Luxon uses 1 = Monday, 7 = Sunday

      while (currentDay === 6 || currentDay === 7 || MARKET_HOLIDAYS[currentYear].holidays.includes(serverDate.toISODate())) {
        // Adjust the date until it's a valid trading day (excluding weekends)
        serverDate = serverDate.minus({ days: 1 });
        currentDay = serverDate.weekday; // Recheck for weekends
      }
      return serverDate.toISODate(); 
    } catch (error) {
      console.error('Error fetching last trading day:', error);
    }
  };

  export const getDateWhenClosesOrOpens = async (marketStatus = null) => { 
    try {
        if (!marketStatus) {
            marketStatus = await getMarketStatus();
        }

        // Get server time in UTC and convert it to Eastern Time using luxon
        const serverTime = marketStatus.serverTime;
        let estDate = DateTime.fromISO(serverTime, { zone: 'America/New_York' });

        const currentYear = estDate.year; // Get the current year
        let possibleLastTradingDay = estDate.toISODate(); // Format as YYYY-MM-DD

        if (marketStatus.status === 'open') {
            // Handle early close for holidays
            if (MARKET_HOLIDAYS[currentYear].earlyClose.includes(possibleLastTradingDay)) {
                estDate = estDate.set({ hour: 13, minute: 0, second: 0, millisecond: 0 }); // 1:00 PM ET (early close)
            } else {
                estDate = estDate.set({ hour: 16, minute: 0, second: 0, millisecond: 0 }); // 4:00 PM ET (regular close)
            }

            return estDate.toMillis(); // ISO time stamp in millis
        } else {
            // Adjust the date until it's a valid trading day (excluding weekends and holidays)
            const today = DateTime.now().setZone('America/New_York').toISODate();

            while (
                MARKET_HOLIDAYS[currentYear].holidays.includes(possibleLastTradingDay) || 
                estDate.weekday === 6 || // Saturday
                estDate.weekday === 7 || // Sunday
                today === possibleLastTradingDay // Same day, move to the next day
            ) {
                estDate = estDate.plus({ days: 1 });
                possibleLastTradingDay = estDate.toISODate(); // Recheck for holidays and weekends
            }

            // Set time to 9:30 AM ET (market open)
            estDate = estDate.set({ hour: 9, minute: 30, second: 0, millisecond: 0 });
            return estDate.toMillis(); // ISO time stamp in millis
        }
    } catch (error) {
        console.error('Error fetching next trading day:', error);
        return null;
    }
};

export const calculateTimeRemaining = (nextEventTimeStamp) => {
    const now = new Date().getTime();
    const difference = nextEventTimeStamp - now;
  
    if (difference <= 0) {
      return {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }; // Event has passed or no time remaining
    }
  
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  
    return {
      hours,
      minutes,
      seconds
    };
  };


export const timespanToMilliseconds = (multiplier, timespan) => {
    const multipliers = parseInt(multiplier);
    let milliseconds = multipliers;
    switch (timespan) {
      case 'second':
      case 'seconds':
        milliseconds *= 1000;
        break;
      case 'minute':
      case 'minutes':
        milliseconds *= 60 * 1000;
        break;
      case 'hour':
      case 'hours':
        milliseconds *= 60 * 60 * 1000;
        break;
      case 'day':
      case 'days':
        milliseconds *= 24 * 60 * 60 * 1000;
        break;
      case 'week':
      case 'weeks':
        milliseconds *= 7 * 24 * 60 * 60 * 1000;
        break;
      default:
        milliseconds = null; // Handle invalid timespan
    }
    return milliseconds;
  };
  const maxGreen = '#DDF8E6'; // Max green at +10%
  const minGreen = '#FAFEFC'; // Min green for any positive change
  
  const maxRed = '#FAE9EA';   // Max red at -10%
  const minRed = '#FDF8F9';   // Min red for any negative change
  
  // Helper to convert hex to RGB
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  };
  
  // Helper to convert RGB to hex
  const rgbToHex = (r, g, b) => {
    return (
      '#' +
      ((1 << 24) + (r << 16) + (g << 8) + b)
        .toString(16)
        .slice(1)
        .toUpperCase()
    );
  };
  
  // Interpolates between two colors based on a factor (0 to 1)
  const interpolateColor = (color1, color2, factor) => {
    const rgb1 = hexToRgb(color1);
    const rgb2 = hexToRgb(color2);
    const resultRgb = rgb1.map((val, i) => Math.round(val + (rgb2[i] - val) * factor));
    return rgbToHex(...resultRgb);
  };
  
  // Main function to calculate color based on change
  export const getChangeColor = (change) => {
    const maxChange = 10; // Max limit for both positive and negative change
  
    // Cap at max green if >= 10% and at max red if <= -10%
    if (change >= maxChange) {
      return maxGreen;
    } else if (change <= -maxChange) {
      return maxRed;
    }
  
    // For positive changes, interpolate between minGreen and maxGreen
    if (change > 0) {
      const factor = change / maxChange; // Scale factor (0 to 1) between 0% and 10%
      return interpolateColor(minGreen, maxGreen, factor);
    }
  
    // For negative changes, interpolate between minRed and maxRed
    if (change < 0) {
      const factor = -change / maxChange; // Scale factor (0 to 1) between 0% and -10%
      return interpolateColor(minRed, maxRed, factor);
    }
  
    // If change is 0, return minGreen
    return minGreen; 
  };




export const MARKET_HOLIDAYS = {
    2024: {
      holidays: [
        "2024-01-01", // New Year's Day
        "2024-01-15", // Martin Luther King, Jr. Day
        "2024-02-19", // Washington's Birthday
        "2024-03-29", // Good Friday
        "2024-05-27", // Memorial Day
        "2024-06-19", // Juneteenth National Independence Day
        "2024-07-04", // Independence Day
        "2024-09-02", // Labor Day
        "2024-11-28", // Thanksgiving Day
        "2024-12-25"  // Christmas Day
      ],
      earlyClose: [
        "2024-07-03", // Independence Day Early Close
        "2024-11-29", // Day after Thanksgiving Early Close
        "2024-12-24"  // Christmas Eve Early Close
      ]
    },
    2025: {
      holidays: [
        "2025-01-01", // New Year's Day
        "2025-01-20", // Martin Luther King, Jr. Day
        "2025-02-17", // Washington's Birthday
        "2025-04-18", // Good Friday
        "2025-05-26", // Memorial Day
        "2025-06-19", // Juneteenth National Independence Day
        "2025-07-04", // Independence Day
        "2025-09-01", // Labor Day
        "2025-11-27", // Thanksgiving Day
        "2025-12-25"  // Christmas Day
      ],
      earlyClose: [
        "2025-07-03", // Independence Day Early Close
        "2025-11-28", // Day after Thanksgiving Early Close
        "2025-12-24"  // Christmas Eve Early Close
      ]
    },
    2026: {
      holidays: [
        "2026-01-01", // New Year's Day
        "2026-01-19", // Martin Luther King, Jr. Day
        "2026-02-16", // Washington's Birthday
        "2026-04-03", // Good Friday
        "2026-05-25", // Memorial Day
        "2026-06-19", // Juneteenth National Independence Day
        "2026-07-03", // Independence Day (Observed)
        "2026-09-07", // Labor Day
        "2026-11-26", // Thanksgiving Day
        "2026-12-25"  // Christmas Day
      ],
      earlyClose: [
        "2026-07-02", // Independence Day Early Close
        "2026-11-27", // Day after Thanksgiving Early Close
        "2026-12-24"  // Christmas Eve Early Close
      ]
    }
  }; 

