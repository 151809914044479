import React, { useState, useEffect} from 'react';
import { formatTimeSince, timeSince } from '../helper/PostHelper';
import Select from 'react-select';
import { customStylesPlus, customStyles } from '../helper/StylesHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
//eslint-disable-next-line
import { postOptions, sourceOptions, categoryOptions, timeOptions, languageOptions, locationOptions, getFormattedDate, topicOptions, categorizedPosts, sourceGroups } from '../helper/NewsHelper';
import NewsPost from './NewsPost';
import config from '../config';

import axios from 'axios';



export default function News() {


    const [activeTab, setActiveTab] = useState('Finance');
    const [newKeyword, setNewKeyword] = useState('');
    const [keywords, setKeywords] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [selectedSort, setSelectedSort] = useState("relevance");
    const [selectedSources, setSelectedSources] = useState([]);
    const [selectedTimeFrame, setSelectedTimeFrame] = useState({value: '1 Day', label: '1 Day'});
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState({value: 'us', label: 'United States'});
    const [selectedRefine, setSelectedRefine] = useState([]);
    const [selectedSourceGroup, setSelectedSourceGroup] = useState({value:'top10', label: 'Top 10 Global Sources'});
    const [newsData, setNewsData] = useState({ articles: [], stories: [] });
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        fetchNewsData();
        //eslint-disable-next-line
    }, [activeTab, selectedLanguages, selectedLocation, selectedRefine, selectedSources, selectedTimeFrame, selectedTopics, selectedSourceGroup,  selectedSort, keywords]);




  // Function to determine the `from` and `to` dates based on the time frame
  const getDateRange = () => {
    if (selectedTimeFrame) {
      const { value } = selectedTimeFrame;
      const to = getFormattedDate(0); // Today
      let from;

      switch (value) {
        case '1 Day':
          from = getFormattedDate(1); // Yesterday
          break;
        case '1 Week':
          from = getFormattedDate(7); // 7 days ago
          break;
        case '1 Month':
          from = getFormattedDate(30); // 30 days ago
          break;
        case '1 Year':
          from = getFormattedDate(365); // 365 days ago
          break;
        default:
          from = null; // No filter
      }

      return { from, to };
    }
    return { from: null, to: getFormattedDate(0) }; // Default: no `from`, but `to` is today
  };


  const fetchNewsData = async () => {
    try {
      setLoading(true);

      const { from, to } = getDateRange();

      // Construct query parameters from user input
      const params = new URLSearchParams();

      if (selectedTimeFrame) {
        params.append('from', from || getFormattedDate(0));
        params.append('to', to);
      }

      if (selectedLocation?.value) {
        params.append('country', selectedLocation.value);
      }

      if (selectedSourceGroup?.value) { 
        params.append('sourceGroup', selectedSourceGroup.value);
      }

      params.append('showNumResults', 'true');
      params.append('showReprints', 'false');

      const excludeLabels = ['Non-news', 'Opinion', 'Paid News', 'Roundup', 'Press Release'];
      excludeLabels.forEach(label => {
        params.append('excludeLabel', label);
      });

      if (selectedSort) {
        params.append('sortBy', selectedSort || 'date');
      }

      params.append('activeTab', activeTab);
      params.append('size', '30');

      (selectedSources || []).forEach(source => {
        params.append('sources', source.value);
      });

      (selectedLanguages || []).forEach(lang => {
        params.append('languages', lang.value);
      });

      (selectedTopics || []).forEach(topic => {
        params.append('topics', topic.value);
      });

      if (keywords.length > 0) {
        params.append('keywords', keywords.join(','));
      }

      const response = await axios.get(`${config.backendUrl}/api/news?${params.toString()}`);

      const { articles, stories } = response.data;

  
      // Set both articles and stories in the state
      setNewsData({ articles, stories });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching news data:', error);
      setLoading(false);
    }
};



      // Function to reset all filters
  const handleClearFilters = () => {
    setSelectedTopics([]);
    setSelectedSources([]);
    setSelectedTimeFrame({value: '1 Day', label: '1 Day'});
    setSelectedLanguages([]);
    setSelectedLocation(null);
    setSelectedRefine(null);
    setSelectedSort("relevance");
    setNewKeyword('');
    setKeywords([]);
    setSelectedSourceGroup({value:'top100', label: 'Top 100 Global Sources'});
  };



    const renderNewsPosts = () => {
        if (loading) {
            return <p>Loading...</p>;
        }

        const { articles, stories } = newsData;
        const { mainPost, secondaryPosts, tertiaryPosts, summarizedPosts } = categorizedPosts(articles, stories);

        if (mainPost.length === 0) {
            return <p>No news posts found. Please expand your search filters.</p>;
        }


    
        const renderProps = (post, type) => ({
          type: type,
          ...(post?.title && { title: post?.title }),
          ...(post?.source && { source: post?.source?.domain }),
          ...(post?.imageUrl && { imageUrl: post?.imageUrl }),
          ...((post?.summary && post?.summary.trim()) || post?.description ? { summary: post?.summary || post?.description } : {}),
          ...(post?.url && { url: post?.url }), // Add url if it exists
          ...(post?.keywords && { keywords: post?.keywords.slice(0, 3).map(k => k.name).join(', ') }), // Add up to 3 keyword names
          ...(post?.pubDate && { pubDate: new Date(post?.pubDate).toLocaleDateString() }) // Convert pubDate to local date string
      });
    
        return (
            <div className="NewsFeed">
                <div className="NewsPosts">
                  <div style = {{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
              <span className="RelatedNewsText">Related Articles</span>
              
           
                    <div className="NewsSection1">
                        <div className="NewsMainPostColumn">
                            <NewsPost {...renderProps(mainPost[0], "Main")} />
    
                            <div className="NewsTertiaryRow">
                                <NewsPost {...renderProps(tertiaryPosts[0], "Tertiary")} />
                                <NewsPost {...renderProps(tertiaryPosts[1], "Tertiary")} />
                            </div>
                            <div className="NewsTertiaryRow">
                                <NewsPost {...renderProps(tertiaryPosts[2], "Tertiary")} />
                                <NewsPost {...renderProps(tertiaryPosts[3], "Tertiary")} />
                            </div>
                            <div className="NewsTertiaryRow">
                                <NewsPost {...renderProps(tertiaryPosts[4], "Tertiary")} />
                                <NewsPost {...renderProps(tertiaryPosts[5], "Tertiary")} />
                            </div>
                            <div className="NewsTertiaryRow">
                                <NewsPost {...renderProps(tertiaryPosts[6], "Tertiary")} />
                                <NewsPost {...renderProps(tertiaryPosts[7], "Tertiary")} />
                            </div>
                        </div>
    
                        <div className="NewsSecondaryPostColumn">
                            {secondaryPosts.map((post, index) => (
                                <React.Fragment key={index}>
                                    <NewsPost {...renderProps(post, "Secondary")} />
                                    {index < secondaryPosts.length - 1 && <hr />}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                    </div>
                    
                </div>
               
                <div className="SummarizedStories">
               { summarizedPosts?.length > 0 && <span className="RelatedNewsText">Related Stories</span> }
                <ul className="SummarizedStoriesList">
    {summarizedPosts.map((story, index) => {
   

        return (
            <li
                key={index}
                className="SummarizedStoryItem"
                style={{ cursor: story?.url ? 'pointer' : 'auto' }}
                onClick={() => {
                    if (story?.url) {
                        window.open(story?.url, '_blank', 'noopener,noreferrer');
                    }
                }}
            >
                {story?.title && <h3 className="SummarizedStoryTitle bold">{story?.title}</h3>}
                
                { story?.pubDate && (
                    <span className="SummarizedStoryTime">updated {formatTimeSince(timeSince(story?.pubDate))}</span>
                )}
            </li>
        );
    })}
                </ul>
                </div>
            </div>
        );
    };



    const handleInputChange = (e) => {
        setNewKeyword(e.target.value);
      };


      const handleAddKeyword = (e) => {
        if (e.key !== 'Enter') return;  
        e.preventDefault(); 
        if (keywords.includes(newKeyword)) {
          alert('Keyword already added');
          return;
        }
        if (newKeyword.trim() !== '' && !keywords.includes(newKeyword.trim())) {
          setKeywords([...keywords, newKeyword.trim()]);
          setNewKeyword('');
        }
      };

    const handleRemoveKeyword = (keyword) => {
        setKeywords(keywords.filter((kw) => kw !== keyword));
    }


  return (
    <div className="NewsContainer">

<div className="FeedSearchWrapper">
<div className="FeedSearchBarKeywordContainer" style = {{marginRight: keywords.length > 0 ? '1rem' : '0rem'}}>
            {keywords.map((keyword) => (
              <div className="FeedSearchBarKeyword" key={keyword}>
                {keyword}
                <span className='keyword-x'>
                <FontAwesomeIcon icon={faTimes} onClick={() => handleRemoveKeyword(keyword)} />
                </span>
               
                </div>
            ))}
          </div>
          <input
            className="FeedSearchBar bold"
            type="text"
            placeholder="Search keywords"
            value={newKeyword}
            onChange={handleInputChange}
            onKeyDown={handleAddKeyword}
          />
        

      </div>




                        <div className="FeedSortBarWrapper">
            <div className="FeedSortBar" style = {{paddingTop: '0rem'}}>
            

                <div className="FeedSortFiltersRow">
                <div className="FeedSortFilters">
                {/* <Select
              className="FeedCategory"
              value={selectedTopics} // Update state with multiple selections
              onChange={(selectedOption) => setSelectedTopics(selectedOption)} // Update state with single selection
              options={topicOptions}
              isSearchable={false}
              unstyled
              isMulti
              styles={customStylesPlus}
              closeMenuOnSelect={false}
              placeholder={'Category +'} //Category + is the placeholder but it is topic in API, categpry in api is active tab
            /> */}
<Select
  className="FeedSources"
  value={selectedSources}
  onChange={(selectedOptions) => setSelectedSources(selectedOptions)} // Update state
  options={sourceOptions}
  isMulti
  unstyled
  isSearchable={false}
  styles={customStylesPlus}
  closeMenuOnSelect={false}
  placeholder="Sources +"
/>
<Select
  className="FeedSources"
  value={selectedSourceGroup}
  onChange={(selectedOption) => {
    if (selectedSourceGroup?.value === selectedOption?.value) {
      setSelectedSourceGroup(null);
    } else {
      setSelectedSourceGroup(selectedOption);
    }
  }} // Update state
  options={sourceGroups}
  unstyled
  isSearchable={false}
  styles={customStyles}
  closeMenuOnSelect={true}
  placeholder="Source group"
/>
<Select
  className="FeedTimeFrame"
  value={selectedTimeFrame}
  onChange={(selectedOption) => {
    // If the selected option is the same as the current one, reset to null
    if (selectedTimeFrame?.value === selectedOption?.value) {
      setSelectedTimeFrame(null);
    } else {
      setSelectedTimeFrame(selectedOption);
    }
  }}
  options={timeOptions}
  isMulti={false}
  unstyled
  isSearchable={false}
  styles={customStyles}
  closeMenuOnSelect={false}
  placeholder="Timeframe"
/>
<Select
  className="FeedLanguage"
  value={selectedLanguages.map((lang) => languageOptions.find((option) => option.value === lang.value))} // Update state with multiple selections   
  onChange={(selectedOption) => setSelectedLanguages(selectedOption)} // Update state
  options={languageOptions}
  isMulti={true}
  unstyled
  isSearchable={false}
  styles={customStylesPlus}
  closeMenuOnSelect={false}
  placeholder="Language +"
/>
<Select
  className="FeedLocation"
  value={selectedLocation}
  onChange={(selectedOption) => {
    // If the selected option is the same as the current one, reset to null
    if (selectedLocation?.value === selectedOption?.value) {
      setSelectedLocation(null);
    } else {
      setSelectedLocation(selectedOption);
    }
  }}
  options={locationOptions}
  isMulti={false}
  unstyled
  isSearchable={false}
  styles={customStyles}
  closeMenuOnSelect={true}
  placeholder="Location"
/>
{/* <Select
  className="FeedRefine"
  value={selectedRefine}
  onChange={(selectedOption) => setSelectedRefine(selectedOption)} // Update state
  options={refineOptions}
  isMulti={false}
  unstyled
  isSearchable={false}
  styles={customStylesPlus}
  closeMenuOnSelect={true}
  placeholder="Refine"
/> */}
                </div>
                </div>

    <div className="FeedSortItems">
      <span className="FeedSortText">Sort by:</span>
      <Select
        className="FeedSortButton"
        value={postOptions.find((option) => option.value === selectedSort) || null}
        onChange={(selectedOption) => {
          // If the selected option is already selected, reset to null
          if (selectedOption.value === selectedSort) {
            setSelectedSort(null);
          } else {
            setSelectedSort(selectedOption.value);
          }
        }}
        options={postOptions}
        isSearchable={false}
        unstyled
        styles={customStyles}
        placeholder="Type"
      />
    </div>

    <div className='FeedSortItems'>
 <button className="FeedKeywordButton" onClick={async ()=> fetchNewsData()}>
          Search
        </button>
        <button className="cp-AddElementButton" onClick={handleClearFilters}>
          Reset
        </button>
        {/* <button className="cp-AddElementButton" onClick={handleSaveSearch}>
          Save
        </button> */}
        </div>

  </div>



</div>
        {/* Borrowing styles from feed since the subheader style is the same */}
        <div className="FeedSubHeader" >
        <div className="FeedNavItems" style={{ gap: '3rem' }}>
  {categoryOptions.map((category) => (
    <button
      key={category.value}
      className={`FeedNavButton FeedBold ${activeTab === category.value ? 'FeedNavButtonActive' : ''}`}
      onClick={() => setActiveTab(category.value)}
    >
      {category.label}
    </button>
  ))}
</div>

  <div className="FeedNavActions">
    
    <button className="FeedNavActionButton" style = {{color: 'transparent', backgroundColor: 'transparent', cursor:'auto'}}> {/*Hide for now */}
      Edit
    </button>
  </div>
</div>

{renderNewsPosts()}

  </div>
  )
}

