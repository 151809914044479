import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowUp, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import config from '../config';
import { AuthContext } from '../context/AuthContext';
import '../styles/PostView.css';
import retweet from '../assets/retweet.svg';
import Post from './Post';
import { 
  formatTimeSince, 
  timeSince, 
  formatDate, 
  getSentimentColor, 
  calculateTotalCount,
  allowedHTML, 
} from '../helper/PostHelper';
import { fetchUser } from '../helper/ProfileHelper';
import { filterPostsByTime, sortPosts } from '../helper/FeedHelper';
import CircularProgress from '@mui/material/CircularProgress';
import Upvote from './post-actions/Upvote';
import Downvote from './post-actions/Downvote';
import Repost from './post-actions/Repost';
import Dots from './post-actions/Dots';
import Bookmark from './post-actions/Bookmark';
import DOMPurify from 'dompurify';
/**
 * 
 * @typedef {import('../helper/PostHelper').QuotePostDetailsObject} QuotePostDetailsObject
 * @typedef {import('../helper/ProfileHelper').UserObject} UserObject
 * @typedef {import('../helper/PostHelper').PostObject} PostObject
 * 
 */

const PostView = () => {
  const navigate = useNavigate();
  const { postId } = useParams();
  const location = useLocation();

  /** @type {[PostObject, Function]} */
  const [post, setPost] = useState(null);

  /** @type {[QuotePostDetailsObject, Function]} */
  const [quotePostDetails, setQuotePostDetails] = useState(null);

  const [loading, setLoading] = useState(true);
  const { userToken, userId, isAuthenticated } = useContext(AuthContext);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isUpvoted, setIsUpvoted] = useState(false);
  const [isDownvoted, setIsDownvoted] = useState(false);
  const [upvoteCount, setUpvoteCount] = useState(0);
  const [downvoteCount, setDownvoteCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [repostCount, setRepostCount] = useState(0);
  


  /** @type {[UserObject, Function]} */
  const [currentUser, setCurrentUser] = useState(null); 

  const [newComment, setNewComment] = useState('');  

  /** @type {[PostObject[], Function]} */
  const [allComments, setAllComments] = useState([]);

  /** @type {{ copyReposter: UserObject, isCopyRepost: boolean }} */
  const { copyReposter, isCopyRepost } = location.state || {};

  const [selectedTime, setSelectedTime] = useState('All time');
  const [selectedSort, setSelectedSort] = useState('Most recent');

  const [quotePostLoading, setQuotePostLoading] = useState(false);

  const [isCopyReposted, setIsCopyReposted] = useState(false);


  useEffect(() => {
    if (!currentUser || !post) return;
    setIsUpvoted(post.upvotes.some(upvote => upvote.user === currentUser._id));
    setIsDownvoted(post.downvotes.some(downvote => downvote.user === currentUser._id));
    setIsCopyReposted(post.reposts.includes(currentUser._id));
  }, [currentUser, post]);




  

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${config.backendUrl}/api/posts/${postId}`, {
          headers: { Authorization: `Bearer ${userToken}` }
        });
        setPost(response.data);
        setUpvoteCount(calculateTotalCount(response.data.upvotes));
        setDownvoteCount(calculateTotalCount(response.data.downvotes));
        setRepostCount(response.data.reposts.length);
        setCommentsCount(response.data.comments.length);

        if (response.data.quotePostId) {
          fetchQuotePost(response.data.quotePostId);
        }
        if (isAuthenticated) {
          const bookmarkResponse = await axios.get(`${config.backendUrl}/api/bookmarks/${postId}/isBookmarked`, {
            headers: { Authorization: `Bearer ${userToken}` },
          });
          setIsBookmarked(bookmarkResponse.data.isBookmarked);
        }

        const allComments = await Promise.all(
          response.data.comments.map(async (comment) => {
            if (!isAuthenticated) {
              return { ...comment, isBookmarked: false };
            }
            const commentBookmarkResponse = await axios.get(`${config.backendUrl}/api/bookmarks/${comment._id}/isBookmarked`, {
              headers: { Authorization: `Bearer ${userToken}` },
            });
            return {
              ...comment,
              isBookmarked: commentBookmarkResponse.data.isBookmarked,
            };
          })
        );
        setAllComments(allComments);
      } catch (error) {
        console.error('Error fetching post:', error);
      }
      setLoading(false);
    };
    const fetchQuotePost = async (quotePostId) => {
      setQuotePostLoading(true);
      try {
        const response = await axios.get(`${config.backendUrl}/api/posts/${quotePostId}`, {
          headers: { Authorization: `Bearer ${userToken}` }
        });
        setQuotePostDetails(response.data);
      } catch (error) {
        console.error('Error fetching quote post:', error);
      }
      setQuotePostLoading(false);
    };

    const fetchCurrentUser = async () => {
      try {
        const response = await fetchUser(userId, userToken);
        setCurrentUser(response.data);
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    setPost(null);
    setQuotePostDetails(null);

    fetchCurrentUser(); 
    fetchPost(); 
  }, [postId, userToken, isAuthenticated, userId]);



  const handleProfileClick = () => {
    if (!isAuthenticated) {
      alert('Please sign in to view profiles');
      return;
    }
    navigate(`/profile/${post.author.username}`);
  };

  const handleBookmarkClick = async () => {
    if (!isAuthenticated) {
      alert('Please sign in to bookmark posts');
      return;
    }
    try {
      const url = `${config.backendUrl}/api/bookmarks/${postId}`;
      const method = isBookmarked ? 'delete' : 'post';
      await axios({ method, url, headers: { Authorization: `Bearer ${userToken}` } });
      setIsBookmarked(!isBookmarked);
    } catch (error) {
      console.error('Error updating bookmark:', error);
    }
  };



  const handleCommentBookmarkClick = async (commentId, isCurrentlyBookmarked) => {
    if (!isAuthenticated) {
      alert('Please sign in to bookmark comments');
      return;
    }
    try {
      const url = `${config.backendUrl}/api/bookmarks/${commentId}`;
      const method = isCurrentlyBookmarked ? 'delete' : 'post';
      await axios({ method, url, headers: { Authorization: `Bearer ${userToken}` } });

      setAllComments((prevComments) =>
        prevComments.map((comment) =>
          comment._id === commentId
            ? { ...comment, isBookmarked: !isCurrentlyBookmarked }
            : comment
        )
      );
    } catch (error) {
      console.error('Error updating comment bookmark:', error);
    }
  };

 




  const submitComment = async () => {
    
    if (!isAuthenticated){ 
      alert('Please sign in to comment on posts'); 
      return;
    }

    if (newComment.length === 0) {
      alert('Please enter a comment');
      return;
    }
  
    if (newComment.trim() !== '') {
      try {
        const response = await axios.post(
          `${config.backendUrl}/api/posts/${postId}/comments`,
          { content: newComment },
          { headers: { Authorization: `Bearer ${userToken}` } }
        );
  
        const newCommentData = response.data.comment;
          
        newCommentData.author = {
          _id: currentUser._id,
          username: currentUser.username,
          name: currentUser.name,
          isUserVerified: currentUser.isUserVerified,
          profilePicture: currentUser.profilePicture,
        };
  
        const bookmarkResponse = await axios.get(`${config.backendUrl}/api/bookmarks/${newCommentData._id}/isBookmarked`, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
  
        setAllComments((prevComments) => [
          ...prevComments,
          { ...newCommentData, isBookmarked: bookmarkResponse.data.isBookmarked }
        ]);
  
        setCommentsCount((commentsCount) => commentsCount + 1);
  
        setPost((prevPost) => ({
          ...prevPost,
          comments: [...prevPost.comments, newCommentData],
        }));
  
        setNewComment('');
      } catch (error) {
        console.error('Error adding comment:', error);
      }
    }
  };
  
  const handleCommentKeyPress = (e) => {
    if (e.key === 'Enter') {
      submitComment();
    }
  };
  
  const handleCommentButtonClick = () => {
    submitComment();
  };

  const filterComments = () => {
    const filteredCommentsByTime = filterPostsByTime(allComments, selectedTime);
    const sortedComments = sortPosts(filteredCommentsByTime, selectedSort);
    return sortedComments;
  };

  if (loading) {
    return <div className="pv-PostViewContainer loading" > <CircularProgress size="3rem"/></div>;
  }

  if (!post) {
    return <div>Error loading post</div>;
  }

  const refreshFollowingStatus = (authorId) => {
    setCurrentUser((prevUser) => {
      // Ensure immutability by creating a copy of the user object
      const updatedUser = { ...prevUser };
  
      // Check if the user is already in the following list
      if (!updatedUser.following.includes(authorId)) {
        // Append the new user to the following list
        updatedUser.following = [...updatedUser.following, authorId];
      }
  
      return updatedUser;
    });
  };


  return (
    <div className="pv-PostViewContainer">
      <div className="pv-BackToFeedContainer">
        <button onClick={() =>{
          if (post?.parentPostId) {
            navigate(`/post/${post.parentPostId}`);
          } else {
            window.history.back();
          }
        }} className="pv-BackButton">
          <FontAwesomeIcon icon={faArrowLeft} /> <span style = {{color: '#707070'}}>Back</span>
        </button>
      </div>
      {isCopyRepost && (
        <div className="pv-ReposterText">
          {copyReposter.username} reposted{' '}
          <img src={retweet} alt="Repost" className="ActionIcon" />
        </div>
      )}

<div className="pv-PostAuthor" onClick={handleProfileClick}>
        <img src={`${post.author.profilePicture}`} alt="Profile" className="pv-ProfilePic" />
        <div className="pv-AuthorDetails">
          <div className="pv-author">
            {post.author.name}{' '}
            {post.author.verified && (
              <FontAwesomeIcon icon={faCheckCircle} className="pv-Verified" />
            )}
          </div>
          <div className="pv-username">@{post.author.username}</div>
        </div>
      </div>
      <div className="pv-PostTopicLine">
  {post.topicLine && (
    <span>
      {post.topicLine}
      {(post.sentiment) && (
        <span style={{ color: '#707070' }}> · </span>
      )}
    </span>
  )}
  {post.sentiment && (
    <span className="bold" style={{ color: getSentimentColor(post.sentiment) }}>
      {post.sentiment}
    </span>
  )}
</div>
      <h1 className="pv-PostTitle bold">{post.title}</h1>

      <div className="pv-PostContent">
        <div className="pv-PostViewContentDateWrapper">
        <div
        className="pv-PostContentP"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content, allowedHTML) }}
      ></div>
          {post.images &&
            post.images.map((image, index) => (
              <img
                key={index}
                src={`${image}`}
                alt={`Post ${index}`}
                className="pv-PostImage"
              />
            ))}
            {post.caption && <div className="pv-PostCaption">{post.caption}</div>}

            {
              quotePostLoading && <div className="cp-QuotePostContainer loading"><CircularProgress size="3rem"/></div>
            }

          { !quotePostLoading && quotePostDetails && (
            <div
              style={{ cursor: 'pointer' }}
              className="cp-QuotePostContainer"
              onClick={() => navigate(`/post/${quotePostDetails._id}`)}
            >
              <div className="cp-QuotePostHeader">
                <img src={`${quotePostDetails.author.profilePicture}`} alt="Profile" className="cp-QuoteProfilePic" />
                <div className="cp-QuotePostInfoWrapper" style={{ flexDirection: 'column' }}>
                  <div className="cp-QuotePostInfo">
                    <div className="cp-QuotePostAuthor cp-bold">
                      <span className = "bold" style={{ marginRight: !quotePostDetails.isUserVerified ? '0.3rem' : '0rem' }}>
                        {quotePostDetails.author.name}
                      </span>
                      {quotePostDetails.isUserVerified && (
                        <FontAwesomeIcon style={{ marginLeft: '0.1rem', marginRight: '0.4rem' }} icon={faCheckCircle} className="Verified" />
                      )}
                    </div>
                    <div className="cp-QuotePostDetails">
                      @{quotePostDetails.author.username} · {formatTimeSince(timeSince(quotePostDetails.timePosted))} 
                      { quotePostDetails.sentiment && <> <span className="bold" style={{ color: getSentimentColor(quotePostDetails.sentiment) }}> 
                            <span style = {{color: '#707070'}}>· </span>  
                          {quotePostDetails.sentiment}</span> </>}
                    </div>
                  </div>
                  <h2 className = "cp-QuotePostTitle bold">{quotePostDetails.title && <>{quotePostDetails.title }</>}</h2>
                  <div className="cp-QuotePostContent">
                    <div
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(quotePostDetails.content, allowedHTML) }}
                      onClick={(e) => {
                        if (e.target.tagName === 'A') {
                          e.stopPropagation(); // Prevents outer div's onClick from firing
                        }
                      }}
                    ></div>
                  </div>
                  {quotePostDetails.images &&
                    quotePostDetails.images.map((image, index) => (
                      <img key={index} src={`${image}`} alt={`Post ${index}`} className="PostImage-sm" />
                    ))}
                </div>
              </div>
            </div>
          )}
          <div className="pv-PostViewDate bold">{formatDate(post.timePosted)}</div>
        </div>
      </div>
      <div className="PostActions pv-PostActions" onClick={(e) => e.stopPropagation()}>
        <div className="LeftActions" style = {{width: '20%'}}>
          <div className='pv-VoteWrapper' >
          <Upvote
          isAdmin={currentUser?.isAdmin}
          isUpvotedState={isUpvoted}
          setIsUpvotedState={setIsUpvoted}
          setIsDownvotedState={setIsDownvoted}
          setUpvoteCount={setUpvoteCount}
          setDownvoteCount={setDownvoteCount}
          postId={postId}
          upvoteCount={upvoteCount}
          />
          </div>
          <div className='pv-VoteWrapper'>
          <Downvote
          isAdmin={currentUser?.isAdmin}
          isDownvotedState={isDownvoted}
          setIsUpvotedState={setIsUpvoted}
          setIsDownvotedState={setIsDownvoted}
          setUpvoteCount={setUpvoteCount}
          setDownvoteCount={setDownvoteCount}
          postId={postId}
          downvoteCount={downvoteCount}
          />
      
          </div>

          <div className='pv-VoteWrapper'>
              <Repost
              postId={postId} 
              profilePic={post.author.profilePicture}
              author={post.author.name}
              verified={post.author.isUserVerified}
              username={post.author.username}
              content={post.content}
              title={post.title}
              time={formatTimeSince(timeSince(post.timePosted))}
              sentiment={post.sentiment}
              type={post.type}
              categories={post.categories}
              images={post.images}
              repostCount={repostCount}
              setRepostCount={setRepostCount}
              isCopyReposted={isCopyReposted}
              setIsCopyReposted={setIsCopyReposted}
              downvoteCount={downvoteCount}
              />
        </div>
        </div>
        <div className="RightActions">
          <Bookmark
          isBookmarked={isBookmarked}
          setIsBookmarked={setIsBookmarked}
          onBookmarkClick={handleBookmarkClick}
          />

          <Dots
          title={post.title}
          content={post.content}
          categories={post.categories}
          sentiment={post.sentiment}
          type={post.type}
          caption={post.caption}
          images={post.images}
          isQuotePost={post.isQuotePost}
          quotePostId={post.quotePostId}
          postId={postId}
          isAdmin={currentUser?.isAdmin}
          username={post.author.username}
          isComment={false}
          parentPostId={post.parentPostId}
          isCopyRepost={isCopyRepost}
          refreshFeed={() => navigate('/feed')}
          />
        </div>
      </div>

      <div className="pv-CommentsSection">
        <div className="pv-CommentsText">Comments ({commentsCount})</div>
        <div className="pv-CommentsInputWrapper">
        <input
          className="pv-CommentsInput"
          type="text"
          placeholder="Add a comment"
          value={newComment}
          onChange={(e) => { if (isAuthenticated) setNewComment(e.target.value); }}
          onKeyDown={handleCommentKeyPress}
        />
        <button className="pv-CommentsInputSubmit" onClick={handleCommentButtonClick}>
          <FontAwesomeIcon icon={faArrowUp} />
          </button>
        </div>
        <div className="TopBar" style={{ padding: '2rem 0' }}>
          <select className="TopBarButton bold" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)}>
            <option value="All time">All time</option>
            <option value="Today">Today</option>
            <option value="7 days">Last 7 days</option>
            <option value="1 month">Last 1 month</option>
          </select>
          <select className="TopBarButton bold" value={selectedSort} onChange={(e) => setSelectedSort(e.target.value)}>
            <option value="Most recent">Most recent</option>
            <option value="Most popular">Most popular</option>
            <option value="Most commented">Most commented</option>
          </select>
        </div>
        <hr style={{margin: '0'}}/>
        <div className="pv-CommentList">
          {filterComments().length === 0 && <div style={{ textAlign: 'center' }}>No comments found</div>}
          {filterComments().map((comment) => (
            <Post
              key={comment._id}
              postId={comment._id}
              profilePic={`${comment.author.profilePicture}`}
              author={comment.author.name}
              verified={comment.author.isUserVerified}
              username={comment.author.username}
              time={formatTimeSince(timeSince(comment.timePosted))}
              content={comment.content}
              tradeDetails={comment.tradeDetails}
              comments={comment.comments}
              reposts={comment.reposts}
              upvotes={comment.upvotes}
              downvotes={comment.downvotes}
              title={comment.title}
              sentiment={comment.sentiment}
              type={comment.type}
              categories={comment.categories}
              bookmarked={comment.isBookmarked}
              isAdmin={currentUser?.isAdmin}  
              onProfileClick={() => {
                if (!isAuthenticated) {
                  alert('Please sign in to view profiles');
                  return;
                }
                navigate(`/profile/${comment.author.username}`);
              }}
              onBookmarkClick={() => handleCommentBookmarkClick(comment._id, comment.isBookmarked)}
              isQuotePost={comment.isQuotePost}
              quotePostId={comment.quotePostId}
              images={comment.images}
              isCopyRepost={false}
              isDownvoted={comment.downvotes.some(downvote => downvote.user === currentUser?._id)}
              isUpvoted={comment.upvotes.some(upvote => upvote.user === currentUser?._id)}
              caption={comment.caption}
              parentPostId={postId}
              isComment={true}
              refreshFeed={() => {
                setAllComments(allComments.filter((c) => c._id !== comment._id));
                setCommentsCount(commentsCount => commentsCount - 1);
              }}
              isAuthorFollowed={ currentUser?.following.includes(comment.author._id) || comment.author._id === currentUser?._id}
              authorId={post.author._id}
              refreshFollowingStatus={refreshFollowingStatus}

            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PostView;
