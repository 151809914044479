export const postOptions = [
    { value: 'relevance', label: 'Relevance' },
    { value: 'date', label: 'Time' },
];

export const sourceOptions = [
    { value: 'cnn.com', label: 'CNN' },
    { value: 'bbc.com', label: 'BBC' },
    { value: 'nytimes.com', label: 'NYTimes' },
    { value: 'nbc.com', label: 'NBC' },
    { value: 'reuters.com', label: 'Reuters' },
    { value: 'theguardian.com', label: 'The Guardian' },
    { value: 'forbes.com', label: 'Forbes' },
    { value: 'wsj.com', label: 'Wall Street Journal' },
    { value: 'foxnews.com', label: 'Fox News' },
    { value: 'aljazeera.com', label: 'Al Jazeera' },
    { value: 'bloomberg.com', label: 'Bloomberg' },
    { value: 'washingtonpost.com', label: 'Washington Post' },
    { value: 'businessinsider.com', label: 'Business Insider' },
    { value: 'npr.org', label: 'NPR' },
    { value: 'abcnews.go.com', label: 'ABC News' }
];

export const sourceGroups = [
    { value: 'top10', label: 'Top 10 Global Sources' },
    { value: 'top100', label: 'Top 100 Global Sources' },
    { value: 'top500English', label: 'Top 500 English Sources' },
    { value: 'top25crypto', label: 'Top 25 Crypto Sources' },
    { value: 'top25finance', label: 'Top 25 Finance Sources' },
    { value: 'top50tech', label: 'Top 50 Tech Sources' },
    { value: 'top100sports', label: 'Top 100 Sports Sources' },
    { value: 'top100leftUS', label: 'Top 100 Left-Leaning US Sources' },
    { value: 'top100rightUS', label: 'Top 100 Right-Leaning US Sources' },
    { value: 'top100centerUS', label: 'Top 100 Center US Sources' },
  ];
export const categoryOptions = [
    { value: 'Frontpage', label: 'All' },
    { value: 'Finance', label: 'Finance' },
    { value: 'Tech', label: 'Tech' },
    { value: 'Politics', label: 'Politics' },
    { value: 'Business', label: 'Business' },
    { value: 'Science', label: 'Science' },
    { value: 'Weather', label: 'Weather' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Lifestyle', label: 'Lifestyle' },
    { value: 'Environment', label: 'Environment' },
    { value: 'Entertainment', label: 'Entertainment' },
    { value: 'Health', label: 'Health' },
    { value: 'Travel', label: 'Travel' },
    { value: 'Auto', label: 'Auto' },
    { value: 'General', label: 'General' },
];

export const topicOptions = [
    { value: 'Artificial Intelligence', label: 'Artificial Intelligence' },
    { value: 'Cryptocurrency', label: 'Cryptocurrency' },
    { value: 'Climate Change', label: 'Climate Change' },
    { value: 'Electronics', label: 'Electronics' },
    { value: 'Economy', label: 'Economy' },
    { value: 'FinTech', label: 'FinTech' },
    { value: 'Crime', label: 'Crime' },
    { value: 'Internet of Things', label: 'Internet of Things' },
    { value: 'Quantum Computing', label: 'Quantum Computing' },
    { value: 'Real Estate', label: 'Real Estate' },
    { value: 'Personal Finance', label: 'Personal Finance' },
    { value: 'World Politics', label: 'World Politics' },
];

export const timeOptions = [ 
    { value: 'All', label: 'All' },
    { value: '1 Day', label: '1 Day' },
    { value: '1 Week', label: '1 Week' },
    { value: '1 Month', label: '1 Month' },
    { value: '1 Year', label: '1 Year' },
];

export const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Spanish' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'zh', label: 'Chinese' },
    { value: 'ar', label: 'Arabic' },
    { value: 'hi', label: 'Hindi' },
    { value: 'ru', label: 'Russian' },
    { value: 'pt', label: 'Portuguese' },
    { value: 'ja', label: 'Japanese' },
    { value: 'it', label: 'Italian' },
    { value: 'ko', label: 'Korean' }
];
export const locationOptions = [
    { value: 'us', label: 'United States' },
    { value: 'gb', label: 'United Kingdom' },
    { value: 'ca', label: 'Canada' },
    { value: 'au', label: 'Australia' },
    { value: 'in', label: 'India' },
    { value: 'cn', label: 'China' },
    { value: 'jp', label: 'Japan' },
    { value: 'de', label: 'Germany' },
    { value: 'fr', label: 'France' },
    { value: 'br', label: 'Brazil' },
    { value: 'ru', label: 'Russia' },
    { value: 'za', label: 'South Africa' },
    { value: 'mx', label: 'Mexico' },
    { value: 'it', label: 'Italy' },
    { value: 'kr', label: 'South Korea' }
];
export const refineOptions = [
    { value: 'Most Recent', label: 'Most Recent' },
    { value: 'Most Popular', label: 'Most Popular' },
    { value: 'Most Commented', label: 'Most Commented' },
];
export const getFormattedDate = (daysAgo = 0) => {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);
    return date.toISOString().split('T')[0]; // Returns YYYY-MM-DD
  };
  export const categorizedPosts = (articles, stories) => {
    const mainPost = articles.length > 0 ? [articles[0]] : [];
    const secondaryPosts = articles.slice(1, 9); // Next 8 posts
    const tertiaryPosts = articles.slice(9, 21); // Next 12 posts
    
    // Use stories as summarizedPosts instead of slicing from articles
    const summarizedPosts = stories.slice(0, 9); // Get first 9 stories (limit to 9)
  
    return { mainPost, secondaryPosts, tertiaryPosts, summarizedPosts };
  };

