import React, { useState, useContext, useEffect } from 'react';
import '../styles/Settings.css';
import { AuthContext } from '../context/AuthContext';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Settings() {
  const [isEditing, setIsEditing] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [isPublicState, setIsPublicState] = useState(false);
  const [vestrMembership, setVestrMembership] = useState(null); 
  const [membershipStatus, setMembershipStatus] = useState(null); 
  const navigate = useNavigate();



  const { phoneNum, email, isPublic, userToken, userId, setPhoneNum, setIsPublic, setEmail } = useContext(AuthContext);

  useEffect(() => {
    setPhoneNumber(phoneNum);
    setEmailAddress(email);
    setIsPublicState(isPublic);
  }, [phoneNum, email, isPublic]);




  useEffect(() => {
    const fetchMembershipDetails = async () => {
      if (!userToken) return;
      try {
        const response = await axios.get(`${config.backendUrl}/api/payments/membership-details`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (response.status === 200) {
          const fetchedMembership = response.data.vestrMembership;
          const fetchedMembershipStatus = response.data.membershipStatus;

          setVestrMembership(fetchedMembership);
          setMembershipStatus(fetchedMembershipStatus);

        } else {
          console.error('Error fetching membership details:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching membership details:', error);
      }
    };

    fetchMembershipDetails();
  }, [userToken]);

  const handleEditClick = () => {
    if (isEditing) {
      saveChanges();
    }
    setIsEditing(!isEditing);
  };

  const handleResumeMembership = async () => {
    const confirm = window.confirm('Are you sure you want to resume your subscription?');
    if (!confirm) {
      return;
    }
    try {
      const response = await axios.post(`${config.backendUrl}/api/payments/resume-subscription`, {}, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
  
      if (response.status === 200) {
        alert('Your subscription has been resumed.');
        window.location.reload(); // Refresh the page to reflect the updated membership status
      } else {
        alert(response.data.error || 'Failed to resume subscription.');
      }
    } catch (error) {
      console.error('Error resuming membership:', error);
      alert('Error resuming subscription. Please try again later.');
    }
  };


  const handleCancelMembership = async () => {
    const confirm = window.confirm('Are you sure you want to cancel your subscription?');
    if (!confirm) {
      return;
    }
    try {
      const response = await axios.post(`${config.backendUrl}/api/payments/cancel-subscription`, {}, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response.status === 200) {
        alert('Your subscription has been canceled. You will have access until the end of your current period.');
        window.location.reload();
      } else {
        alert(response.data.error || 'Failed to cancel subscription.');
      }
    } catch (error) {
      console.error('Error canceling membership:', error);
      alert('Error canceling subscription. Please try again later.');
    }
  };

  const saveChanges = async () => {
    try {
      const response = await axios.put(`${config.backendUrl}/api/auth/update-profile`, {
        userId,
        phoneNum: phoneNumber,
        email: emailAddress,
        isPublic: isPublicState,
      }, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        }
      });

      if (response.status === 200) {
        setPhoneNum(phoneNumber);
        setEmail(emailAddress);
        setIsPublic(isPublicState);
        localStorage.setItem('phoneNum', phoneNumber);
        localStorage.setItem('email', emailAddress);
        localStorage.setItem('isPublic', isPublicState);
        alert('Profile updated successfully');
      } else {
        alert(response.data.message || 'Error updating profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile');
    }
  };

  return (
    <div className="se-page-container">
      <div className="se-settings-container">
        <h1 className="se-settings-title bold">Settings</h1>
        <button className="se-edit-button bold" onClick={handleEditClick}>
          {isEditing ? 'Save' : 'Edit'}
        </button>

        <div className="se-section">
          <h2 className="se-section-title bold">Account info</h2>
          <div className="se-info-grid">
            <div className="se-info-item">
              <span className="se-info-label">Phone #</span>
              {isEditing ? (
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="se-info-value editable"
                />
              ) : (
                <span className="se-info-value">{phoneNum}</span>
              )}
            </div>
            <div className="se-info-item">
              <span className="se-info-label">Email</span>
              {isEditing ? (
                <input
                  type="email"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  className="se-info-value editable"
                />
              ) : (
                <span className="se-info-value">{email}</span>
              )}
            </div>
          </div>
        </div>

        <div className="se-section">
          <h2 className="se-section-title bold">Security and privacy</h2>
          <div className="se-info-grid">
            <div className="se-info-item">
              <span className="se-info-label">Account visibility</span>
              <div className="se-checkbox-container">
                <input
                  style={{ margin: 0 }}
                  type="checkbox"
                  id="se-public-checkbox"
                  checked={isPublicState}
                  onChange={() => setIsPublicState(!isPublicState)}
                  disabled={true} //always disabled for now
                />
                <label htmlFor="se-public-checkbox">Public</label>
              </div>
            </div>
          </div>
        </div>

        <div className="se-section">
          <h2 className="se-section-title bold">Manage subscription</h2>
          {vestrMembership ? (
            <>
              <p className="se-subscription-details">
                Vestr+ {vestrMembership.type}:{' '}
                {vestrMembership.price && vestrMembership.cycle
                  ? `$${vestrMembership.price.toFixed(2)}/${vestrMembership.cycle}`
                  : 'Free'}
              </p>
              <p className="se-subscription-details">
            {membershipStatus === 'active' ? 'Next payment:' : 'Expires:'}{' '}
            {vestrMembership.expires
              ? new Date(vestrMembership.expires).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              : 'No upcoming payment'}
          </p>
            </>
          ) : (
            <p>Loading membership details...</p>
          )}
          <button onClick= {()=> navigate('/vestrplus')}className="se-link-button bold" style={{ marginTop: '1rem' }}>Change subscription</button>
         { membershipStatus === 'active' && <button onClick = {handleCancelMembership} className="se-link-button bold" style={{ marginTop: '1rem' }}>Cancel subscription</button>}
         {membershipStatus === 'canceled' && <button onClick = {handleResumeMembership} className="se-link-button bold" style={{ marginTop: '1rem' }}>Resume subscription</button>}
        </div>

        <div className="se-policies">
          <span
            onClick={() => navigate('/privacy', {
              state: { fromSettings: true }
            })}
            className="se-terms-link bold">
            Privacy Policy
          </span>
          <span
            onClick={() => navigate('/terms', {
              state: { fromSettings: true }
            })}
            className="se-terms-link bold">
            Terms of Service
          </span>
        </div>
      </div>
    </div>
  );
}