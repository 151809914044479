import React from 'react';
import '../styles/News.css';

export default function NewsPost(
    {
        type,
        title,
        summary,
        source,
        imageUrl,
        url,
        keywords,
        pubDate, // already formatted
    }
) {
    const handleClick = () => {
        if (url) {
          window.open(url, '_blank', 'noopener,noreferrer'); // Open in new tab
        }
    };

    const renderSourceRow = () => {
        let sourceRow = '';
        
        if (pubDate) {
            sourceRow += pubDate;
        }

        if (source) {
            sourceRow += sourceRow ? ` - ${source}` : source;
        }

        if (keywords) {
            sourceRow += sourceRow ? ` - ${keywords}` : keywords;
        }

        return sourceRow;
    };

    if (type === 'Main') {
        return (
            <div className="NewsPostMain" onClick={handleClick} style={{ cursor: url ? 'pointer' : 'auto' }}>
                {imageUrl && <img alt='news-post-img' src={imageUrl} className="NewsPostMainImage" />}
                {title && <h2 style={{ marginTop: !imageUrl && '0rem' }} className='NewsPostTitle bold'>{title}</h2>}
                {summary && <p className="NewsPostMainContent">{summary}</p>}
                {renderSourceRow() && <span className="NewsPostMainSource">{renderSourceRow()}</span>}
            </div>
        );
    } else if (type === 'Secondary') {
        return (
            <div className="NewsPostSecondary" onClick={handleClick} style={{ cursor: url ? 'pointer' : 'auto' }}>
                <div className="NewsPostSecondaryRow" style={{ display: 'flex' }}>
                    <div className='NewsPostSecondaryCol' style={{ display: 'flex', flexDirection: 'column' }}>
                        {title && <h2 className='NewsPostSecondaryTitle bold'>{title}</h2>}
                        {summary && <p className="NewsPostSecondaryContent">{summary}</p>}
                    </div>
                </div>
                {renderSourceRow() && <span className="NewsPostSecondarySource">{renderSourceRow()}</span>}
            </div>
        );
    } else if (type === 'Tertiary') {
        return (
            <div className="NewsPostTertiary" onClick={handleClick} style={{ cursor: url ? 'pointer' : 'auto' }}>
                {imageUrl && <img alt='news-post-img' src={imageUrl} className="NewsPostTertiaryImage" />}
                {title && <h2 style={{ marginTop: !imageUrl && '0rem' }} className='NewsPostTertiaryTitle bold'>{title}</h2>}
                {summary && <p className="NewsPostTertiaryContent">{summary}</p>}
                {renderSourceRow() && <span className="NewsPostTertiarySource">{renderSourceRow()}</span>}
            </div>
        );
    }

    return null; // In case the type doesn't match, return nothing.
}